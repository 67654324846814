import styled from "styled-components";
import { Form } from "formik";
import { device } from "../../../utils/constants/mediaQueries";

export const StyledFormikField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  label {
    font-weight: 700;
    color: var(--text-color);
    letter-spacing: 0.5px;
  }

  .p-inputtext {
    padding: 0.6rem 0.75rem;
    font-size: 0.9rem;
    color: var(--text-color);
    font-weight: 500;
  }

  .input-error-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    &__error-msg {
      color: var(--error-msg);
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  &.field--disabled {
    label {
      color: var(--gray-500);
    }
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 1rem;

  @media ${device.sm} {
    gap: 1.5rem;
  }

  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .add-person-btn {
    width: fit-content;
  }

  Button {
    width: 100%;
  }

  .submit-btn {
    margin-top: 1.5rem;

    @media ${device.md} {
      margin-top: 2rem;
    }
  }
`;
