import React from "react";
import styled from "styled-components";
import MyAppLayout from "../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../components/Layout/Layout/TextPageLayout";
import Localized from "../../components/intl/Localized";
import bg from "../../images/dresscode/dresscodeBg.png";
import { device } from "../../utils/constants/mediaQueries";
import femIcon from "../../images/svg/fem.svg";
import mascIcon from "../../images/svg/masc.svg";
import suits from "../../images/dresscode/suitsColor.png";
import dresses from "../../images/dresscode/dressesColor.png";
import useLangSearchParam from "../../utils/hooks/useLangSearchParam";
import pinterestLogo from "../../images/dresscode/pinterestLogo.png";

interface DresscodePageProps {}

const DresscodePage = (props: DresscodePageProps) => {
  const { lang } = useLangSearchParam("en");

  const isItalian = lang === "it";

  const linkToPinterest = "https://pin.it/2NIWFmd8z";

  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="background-img">
            <h1>
              <Localized id="dresscode.h1" defaultMessage="Dresscode" />
            </h1>
          </div>
          <section className="text-section">
            <p>
              <Localized
                id="dresscode.p1"
                defaultMessage="We wish to maintain a formal dress code that harmonizes with the beautiful Italian setting and the warm climate. We invite you to wear both dark suits and lighter alternatives suitable for warmer days; this includes dresses and suits made of linen and cotton fabrics. Therefore, our dress code strikes a balance between formal elegance and summery lightness, where each guest feels comfortable and chic!"
              />
            </p>
            <p>
              <Localized
                id="dresscode.p2"
                defaultMessage="To make it even easier for you to understand what we mean, we have created a list of feminine and masculine outfits that fit within our dress code. Additionally, we have also put together a Pinterest board with outfit inspiration. As you can see from the inspiration images, we encourage choosing colorful gowns/summer dresses, but it is also perfectly acceptable to opt for a simple black dress. For those who choose to wear a suit, dark or light suits are appropriate, but of course, experimenting with colors in ties and scarves is certainly allowed."
              />
            </p>
            <a
              href={linkToPinterest}
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              <img
                className="pinterest-logo"
                src={pinterestLogo}
                alt="Pinterest logo"
              />
              <Localized
                id="dresscode.link1"
                defaultMessage="Go to pinterest board for outfit inspiration."
              />
            </a>
          </section>

          <img
            className="illustration-img"
            src={suits}
            alt="Illustration of different suits"
          />

          <section className="dresscode-box-section">
            <div className="dresscode-box">
              <img className="icon" src={femIcon} alt="Female symbol" />
              <ul>
                <li>
                  <Localized id="dresscode.fem-li1" defaultMessage="Gown" />
                </li>
                <li>
                  <Localized
                    id="dresscode.fem-li2"
                    defaultMessage="Long/midi coctail dress"
                  />
                </li>
                <li>
                  <Localized
                    id="dresscode.fem-li3"
                    defaultMessage="Maxi/midi sundress"
                  />
                </li>

                <li>
                  <Localized id="dresscode.fem-li4" defaultMessage="Jumpsuit" />
                </li>
              </ul>
            </div>

            <div className="dresscode-box">
              <img className="icon" src={mascIcon} alt="Male symbol" />
              <ul>
                <li>
                  <Localized id="dresscode.masc-li1" defaultMessage="Tuxedo" />
                </li>
                <li>
                  <Localized
                    id="dresscode.masc-li2"
                    defaultMessage="Dark suit with bow/tie"
                  />
                </li>
                <li>
                  <Localized
                    id="dresscode.masc-li3"
                    defaultMessage="Light suit with bow/tie"
                  />
                </li>
                <li>
                  <Localized
                    id="dresscode.masc-li4"
                    defaultMessage="Linen suit"
                  />
                </li>
              </ul>
            </div>
          </section>

          <img
            className="illustration-img"
            src={dresses}
            alt="Illustration of different dresses"
          />
          {!isItalian && (
            <section className="tips-section">
              <h2>
                <Localized id="dresscode.h2" defaultMessage="Practical tips" />
              </h2>
              <ul>
                <li>
                  <Localized
                    id="dresscode.tips-li1"
                    defaultMessage="Since we are celebrating in September, temperatures can vary. It may be warm during the day but cooler in the evening. Feel free to bring a shawl or a light jacket to stay warm. For those who easily get warm, choosing outfits made of linen or cotton may be ideal to stay cool."
                  />
                </li>
                <li>
                  <Localized
                    id="dresscode.tips-li2"
                    defaultMessage="Check the Weather Forecast! While September typically sees few rainy days in Italy, it's not impossible (knock on wood it doesn’t happen). In the unlikely event of rain, we have a Plan B to move indoors. However, bringing an umbrella might still be a wise choice if its going to rain. Should we be blessed with the sunshine we've obviously requested, sunglasses and any other sun protection could come in very handy!"
                  />
                </li>
                <li>
                  <Localized
                    id="dresscode.tips-li3"
                    defaultMessage="Much of the celebration will take place outdoors and on grass. Choose shoes that are practical for the terrain. Stiletto heels might be challenging, but if you opt for them, consider using “heel protectors” for better stability."
                  />
                </li>
                <li>
                  <Localized
                    id="dresscode.tips-li4"
                    defaultMessage="For extra comfort throughout the party, consider bringing a pair of flat shoes in your purse if you're wearing high heels. Foldable ballet flats are both practical and space-saving."
                  />
                </li>
              </ul>
            </section>
          )}
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default DresscodePage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
  padding-bottom: 3rem;

  .background-img {
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 200px;
    position: relative;

    @media ${device.sm} {
      height: 300px;
      margin-bottom: 2rem;
    }
  }

  .background-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1); /* White with 0.1 opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  h1 {
    z-index: 100;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      top: 50%;
      font-size: 5rem;
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .dresscode-box-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media ${device.sm} {
      flex-direction: row;
      gap: 4rem;
    }
  }

  .dresscode-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.8rem;
    border: 4px solid white;
    padding: 1.5rem;
    width: 270px;
    height: 270px;

    @media ${device.sm} {
      width: 350px;
      height: 350px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;

      @media ${device.sm} {
        gap: 0.7rem;
        font-size: 1.2rem;
      }
    }

    .icon {
      width: 50px;
      height: 50px;

      @media ${device.sm} {
        width: 80px;
        height: 80px;
      }
    }
  }
  li {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .link {
    color: var(--primary-500);
    font-weight: 900;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.5rem;
    width: fit-content;

    &:hover {
      opacity: 0.6;
    }
  }

  .pinterest-logo {
    width: 25px;
    height: 25px;
  }

  .tips-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    padding: 2rem 1.2rem;
    border-left: 2px solid black;

    @media ${device.sm} {
      padding: 3rem 2rem;
    }

    h2 {
      font-size: 2.5rem;
      font-weight: 700;
    }

    ul {
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .illustration-img {
    width: 350px;

    @media ${device.sm} {
      width: 500px;
    }
  }
`;
