import React from "react";
import styled from "styled-components";
import Localized from "../../components/intl/Localized";
import MyAppLayout from "../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../components/Layout/Layout/TextPageLayout";
import { device } from "../../utils/constants/mediaQueries";
import WeddingDayPage from "../Program/WeddingDay";
import qanda from "../../images/qAndA/Q&A.png";
import qr from "../../images/qAndA/qr.jpg";
import { Accordion, AccordionTab } from "primereact/accordion";

interface QandAPageProps {}

export const QandAPage = (props: QandAPageProps) => {
  const dotsLink = "https://onelifesocial.page.link/nM9S";
  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="background-img">
            <h1>
              <Localized id="qanda.h1" defaultMessage="Q&A" />
            </h1>
          </div>

          <section className="accordion-section">
            <Accordion multiple activeIndex={[0]}>
              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-1"
                    defaultMessage="What kind of food can we expect?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s1-p1"
                    defaultMessage="There will be plenty of food! We will start with an antipasti buffet, followed by dinner consisting of risotto, pasta, and a main course. Then there will be wedding cake and a dessert buffet. A little tip from us is to pace yourself during the buffet; remember there's a big dinner coming after this! We speak from experience, hehe."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized id="qanda.h3-2" defaultMessage="Allergies" />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s2-p1"
                    defaultMessage="For those of you who informed us about allergies via the RSVP form, there will be food catered to your needs. The event company has received all the necessary information."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized id="qanda.h3-3" defaultMessage="Wedding gift?" />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s3-p1"
                    defaultMessage="For us, it is a gift in itself that you are traveling to Italy to celebrate with us. So we do not expect you to give us a gift in addition to travel and accommodation! However, we have received several inquiries about this, so we are including it here."
                  />
                </p>
                <p className="m-0">
                  <Localized
                    id="qanda.s3-p2"
                    defaultMessage="For those who wish to give us a gift, we would greatly appreciate a contribution to our honeymoon in Crete."
                  />
                </p>
                <ul>
                  <li>
                    <Localized
                      id="qanda.s3-li-1"
                      defaultMessage="Bank account number: 9801 52 85680"
                    />
                  </li>
                  <li>
                    <Localized
                      id="qanda.s3-li-2"
                      defaultMessage="IBAN and BIC: NO74 9801 5285 680 and DNBANOKK"
                    />
                  </li>
                  <li>
                    <Localized
                      id="qanda.s3-li-3"
                      defaultMessage="Please write your name in the message field so we know who the gift is from."
                    />
                    <i className="pi pi-heart" />
                  </li>
                </ul>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-4"
                    defaultMessage="Can we take pictures during the ceremony?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s4-p1"
                    defaultMessage="We would greatly appreciate it if you could keep your phones in a pocket or purse during the ceremony. We would much rather see your lovely faces than your phones. We have hired a photographer and videographer to ensure the moments are captured. After the ceremony, however, feel free to snap away!"
                  />
                  <i className="pi pi-heart" />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-5"
                    defaultMessage="Is there a place to upload and view photos taken by others?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s5-p1"
                    defaultMessage="We have created a wedding album with the app Dots. This is free and allows you to upload photos you take at the welcome party and during the wedding. We would love for as many of you as possible to use this app so that we can see all the photos you take!"
                  />
                  <i className="pi pi-heart" />
                </p>
                <p className="m-0">
                  <Localized
                    id="qanda.s5-p2"
                    defaultMessage="Scan this QR code:"
                  />
                </p>
                <img className="qr-code" src={qr} alt="QR code for Dots app" />
                <a className="link" href={dotsLink}>
                  <Localized
                    id="qanda.s5-p3"
                    defaultMessage="Alternatively, you can visit this link"
                  />
                </a>
                <p className="m-0">
                  <Localized
                    id="qanda.s5-p4"
                    defaultMessage="The referral code is: RX4KCeKg"
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-6"
                    defaultMessage="Will the wedding be held in English, Norwegian, or Italian?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s6-p1"
                    defaultMessage="It will be a delightful mix of all three languages!"
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-7"
                    defaultMessage="Is there a translator at Casal Montani?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s7-p1"
                    defaultMessage="No, but I (Herman) have heard that both Haakon Jordbakke and Håkon Hellemann have an impressive streak of over a hundred days on Duolingo, so if you are unsure of something, you can safely ask one of them."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-8"
                    defaultMessage="Are children invited to the wedding?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s8-p1"
                    defaultMessage="We have set an age limit of 18 years on the wedding day, so it will be a child-free event. However, children are warmly welcome to the welcome party on September 19th."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-9"
                    defaultMessage="Is there parking at Casal Montani?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s9-p1"
                    defaultMessage="Yes, there is parking available on-site."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-10"
                    defaultMessage="Is transportation to and from Casal Montani planned?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s10-p1"
                    defaultMessage="We have arranged buses to take you back to Frascati and Rocca di Papa after the party. We recommend that you book a taxi in advance or look into other transportation options to the wedding."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized id="qanda.h3-11" defaultMessage="Toastmaster" />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s11-p1"
                    defaultMessage="For questions about speeches and other matters, please contact our toastmaster Andreas Tøsse (andreas.tosse@icloud.com)."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                header={
                  <Localized
                    id="qanda.h3-12"
                    defaultMessage="How do we keep in touch with each other during the trip?"
                  />
                }
              >
                <p className="m-0">
                  <Localized
                    id="qanda.s12-p1"
                    defaultMessage="We are setting up a Facebook group for all our guests."
                  />
                </p>
              </AccordionTab>

              <AccordionTab
                className="info-accordion"
                header={
                  <Localized
                    id="qanda.h3-13"
                    defaultMessage="Important times and other practical information"
                  />
                }
              >
                <h4>
                  <Localized
                    id="qanda.s13-p1"
                    defaultMessage="Welcome Party:"
                  />
                </h4>
                <ul>
                  <li>
                    <Localized
                      id="qanda.s13-li1"
                      defaultMessage="Starts at 12:00"
                    />
                  </li>
                  <li>
                    <Localized
                      id="qanda.s13-li2"
                      defaultMessage="Ends at 16:30"
                    />
                  </li>
                  <li>
                    <Localized
                      id="qanda.s13-li3"
                      defaultMessage="Via del Prato Fabio 21, Rocca di Papa, 40, Italy"
                    />
                  </li>
                </ul>
                <h4>
                  <Localized id="qanda.s13-p2" defaultMessage="Wedding Day:" />
                </h4>
                <ul>
                  <li>
                    <Localized
                      id="qanda.s13-li4"
                      defaultMessage="The ceremony starts at 17:00, so we recommend arriving 10 to 15 minutes before."
                    />
                  </li>
                  <li>
                    <Localized
                      id="qanda.s13-li5"
                      defaultMessage="The first bus transport home departs at 01:00."
                    />
                  </li>
                  <li>
                    <Localized
                      id="qanda.s13-li6"
                      defaultMessage="The last bus transport home departs at 02:30."
                    />
                  </li>
                  <li>
                    <Localized
                      id="qanda.s13-li7"
                      defaultMessage="Via di Casal Montani, 00132 Rome RM, Italy"
                    />
                  </li>
                </ul>
              </AccordionTab>
            </Accordion>
          </section>
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default WeddingDayPage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding-bottom: 3rem;

  .background-img {
    background-image: url(${qanda});
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 200px;
    position: relative;

    @media ${device.sm} {
      height: 300px;
      margin-bottom: 2rem;
    }
  }

  .background-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White with 0.5 opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  h1 {
    z-index: 100;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      top: 50%;
      font-size: 5rem;
    }
  }

  .link {
    color: var(--primary-500);
    font-weight: 700;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.5rem;
    width: fit-content;

    &:hover {
      opacity: 0.6;
    }
  }

  .accordion-section {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  i {
    font-size: 1rem;
    margin-left: 0.5rem;
  }

  .p-accordion-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  h4 {
    font-family: "Cormorant", serif;
    font-weight: 900;
    padding-top: 1rem;
  }

  .info-accordion {
    ul {
      list-style: circle;
      padding-left: 1rem;
    }
  }

  .qr-code {
    width: 150px;
    height: 150px;
    padding: 1rem;
  }
`;
