import React from "react";
import styled from "styled-components";
import amalfi from "../../../images/party/amalfi.png";
import femIcon from "../../../images/svg/fem.svg";
import mascIcon from "../../../images/svg/masc.svg";
import tile from "../../../images/party/tile.png";
import lemon from "../../../images/gif/lemongif.gif";
import pinterestLogo from "../../../images/dresscode/pinterestLogo.png";
import MyAppLayout from "../../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../../components/Layout/Layout/TextPageLayout";
import Localized from "../../../components/intl/Localized";
import { device } from "../../../utils/constants/mediaQueries";
import houseIcon from "../../../images/svg/house-icon.svg";

const RehearsalPage = () => {
  const linkToPinterest = "https://pin.it/38jNX4AwK";
  const linkToHouse = "https://www.airbnb.no/rooms/28134383";
  const addressLink = "https://maps.app.goo.gl/woKXiQ9qWfobYhJf6";
  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="background-img">
            <h1>
              <Localized id="rehearsal.h1" defaultMessage="Welcome party" />
            </h1>
          </div>
          <section className="text-section">
            <p>
              <Localized
                id="rehearsal.p1"
                defaultMessage="We are very excited to celebrate our wedding with you in beautiful Italy! To kick off the festivities, we invite you to a welcome party in the garden of the beautiful villa we have rented, complete with a pool and stunning views. This is meant to be a relaxed and pleasant party where you can get to know each other a little better across our families and groups of friends before the big day."
              />
            </p>
            <div className="heading-text-section">
              <h2>
                <Localized id="rehearsal.h2-1" defaultMessage="Date and time" />
              </h2>
              <p>
                <Localized
                  id="rehearsal.p2"
                  defaultMessage="This will be a daytime party on Thursday, September 19th. The party starts at 12:00 PM and wraps up at 4:30 PM, as we want everyone to be fresh and full of energy for the next day."
                />
              </p>
            </div>

            <div className="heading-text-section">
              <h2>
                <Localized
                  id="rehearsal.h2-2"
                  defaultMessage="Dress code - Coastal Chic"
                />
              </h2>
              <p>
                <Localized
                  id="rehearsal.p3"
                  defaultMessage="Think of the elegant but relaxed style of a beach bar on the Amalfi Coast or a beach club in St. Tropez. We want a balance between casual and sophisticated, where you can enjoy the sun and the pool while still feeling stylish and comfortable. You are welcome to swim if the weather allows, but please note that we do not have shower facilities available afterward."
                />
              </p>
              <a
                href={linkToPinterest}
                target="_blank"
                rel="noreferrer noopener"
                className="link"
              >
                <img
                  className="pinterest-logo"
                  src={pinterestLogo}
                  alt="Pinterest logo"
                />
                <Localized
                  id="rehearsal.pinterest-link"
                  defaultMessage="For outfit inspiration, I have created a Pinterest board that you can check out."
                />
              </a>
            </div>
          </section>

          <img
            className="illustration-img"
            src={tile}
            alt="Illustration of an italian tile with blue and yellow colors"
          />

          <section className="dresscode-box-section">
            <div className="dresscode-box">
              <img className="icon" src={femIcon} alt="Female symbol" />
              <ul>
                <li>
                  <Localized
                    id="rehearsal.fem-li1"
                    defaultMessage="Light summer dresses"
                  />
                </li>
                <li>
                  <Localized
                    id="rehearsal.fem-li2"
                    defaultMessage="Sarongs over swimwear"
                  />
                </li>
                <li>
                  <Localized
                    id="rehearsal.fem-li3"
                    defaultMessage="Skirts or shorts with a nice top"
                  />
                </li>

                <li>
                  <Localized
                    id="rehearsal.fem-li4"
                    defaultMessage="Sandals, espadrilles, or other flat shoes"
                  />
                </li>
                <li>
                  <Localized
                    id="rehearsal.fem-li5"
                    defaultMessage="Large sun hats and sunglasses for a touch of glamour"
                  />
                </li>
              </ul>
            </div>

            <div className="dresscode-box">
              <img className="icon" src={mascIcon} alt="Male symbol" />
              <ul>
                <li>
                  <Localized
                    id="rehearsal.masc-li1"
                    defaultMessage="Light linen trousers, chinos, or shorts"
                  />
                </li>
                <li>
                  <Localized
                    id="rehearsal.masc-li2"
                    defaultMessage="Polo shirts, shirts, or t-shirts"
                  />
                </li>
                <li>
                  <Localized
                    id="rehearsal.masc-li3"
                    defaultMessage="Swim trunks that can be styled with a light shirt"
                  />
                </li>
                <li>
                  <Localized
                    id="rehearsal.masc-li4"
                    defaultMessage="Stylish hats and sunglasses to spice up the outfit"
                  />
                </li>
                <li>
                  <Localized
                    id="rehearsal.masc-li5"
                    defaultMessage="Loafers, fresh sneakers, sandals, or boat shoes"
                  />
                </li>
              </ul>
            </div>
          </section>

          <img
            className="illustration-img"
            src={tile}
            alt="Illustration of an italian tile with blue and yellow colors"
          />
          <section className="text-section">
            <div className="heading-text-section">
              <h2>
                <Localized
                  id="rehearsal.h2-3"
                  defaultMessage="Food and drinks"
                />
              </h2>
              <p>
                <Localized
                  id="rehearsal.p5"
                  defaultMessage="There will be a delightful mix of drinks such as beer, wine, various spritzes, and non-alcoholic options. For food, there will be a simple but delicious Italian buffet served around 1:30 PM. There will also be options for those with allergies who have informed us that they will be attending the party."
                />
              </p>
            </div>

            <div className="heading-text-section">
              <h2>
                <Localized id="rehearsal.h2-4" defaultMessage="Children" />
              </h2>
              <p>
                <Localized
                  id="rehearsal.p6"
                  defaultMessage="You are welcome to bring children to the party, but please note that there will be moderately loud music and an unfenced pool. We do not have dedicated people to supervise the pool, so we ask parents to keep a close eye on their little ones."
                />
              </p>
            </div>

            <div className="heading-text-section">
              <h2>
                <Localized id="rehearsal.p8" defaultMessage="The address is:" />
              </h2>
              <a
                href={addressLink}
                target="_blank"
                rel="noreferrer noopener"
                className="link"
              >
                "Via del Prato Fabio 21, Rocca di Papa, 40, Italia"
              </a>
              <a
                href={linkToHouse}
                target="_blank"
                rel="noreferrer noopener"
                className="link"
              >
                <img
                  className="house-icon"
                  src={houseIcon}
                  alt="Illustration of a house"
                />
                <Localized
                  id="accommodation.link2"
                  defaultMessage="Link to our Airbnb villa"
                />
              </a>
            </div>
            <p>
              <Localized
                id="rehearsal.p7"
                defaultMessage="We look forward to a day filled with laughter, music, good food and drinks, and of course, many good conversations and dancing. Think sun, sea, and aperol spritz – it's the perfect start to our wedding weekend!"
              />
            </p>
          </section>

          <img
            className="illustration-img"
            src={lemon}
            alt="Lemon illustration"
          />
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default RehearsalPage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding-bottom: 3rem;

  .background-img {
    background-image: url(${amalfi});
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 200px;
    position: relative;

    @media ${device.sm} {
      height: 300px;
      margin-bottom: 2rem;
    }
  }
  .background-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White with 0.5 opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  h1 {
    z-index: 100;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      top: 50%;
      font-size: 5rem;
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .heading-text-section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .dresscode-box-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media ${device.sm} {
      flex-direction: row;
      gap: 4rem;
    }
  }

  .dresscode-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.8rem;
    border: 4px solid white;
    padding: 1.5rem;
    width: 270px;
    height: 370px;

    @media ${device.sm} {
      width: 350px;
      height: 400px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;

      @media ${device.sm} {
        gap: 0.7rem;
        font-size: 1.2rem;
      }
    }

    .icon {
      width: 50px;
      height: 50px;

      @media ${device.sm} {
        width: 80px;
        height: 80px;
      }
    }
  }

  li {
    font-size: 1.1rem;
    font-weight: 700;
  }

  .link {
    color: var(--primary-500);
    font-weight: 900;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.5rem;
    width: fit-content;

    &:hover {
      opacity: 0.6;
    }
  }

  .pinterest-logo {
    width: 25px;
    height: 25px;
  }

  .illustration-img {
    width: 100px;

    @media ${device.sm} {
      width: 150px;
    }
  }

  .house-icon {
    width: 1rem;
    height: 1rem;
  }
`;
