import styled from "styled-components";
import { device } from "../../../utils/constants/mediaQueries";

interface TextPageLayoutProps {
  children: any;
}

export const TextPageLayout = (props: TextPageLayoutProps) => {
  const { children } = props;

  return <StyledPageLayout>{children}</StyledPageLayout>;
};

export const StyledPageLayout = styled.div`
  margin: 0 auto;
  padding: 4rem 1.4rem;

  @media ${device.sm} {
    padding: 4rem 2rem;
    max-width: 100%;
  }

  @media ${device.md} {
    padding: 5rem 2rem;
    max-width: 100%;
  }

  @media ${device.lg} {
    padding: 5rem 2rem;
    max-width: 900px;
  }

  @media ${device.xl} {
    padding: 5rem 2rem;
    max-width: 900px;
  }
`;
