import { styled } from "styled-components";
import MyAppLayout from "../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../components/Layout/Layout/TextPageLayout";
import Localized from "../../components/intl/Localized";
import { device } from "../../utils/constants/mediaQueries";
import { RSVPForm } from "../../components/Forms/rsvp";

interface RsvpPageProps {}

export const RsvpPage = (props: RsvpPageProps) => {
  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="header-text-section">
            <h1>
              <Localized id="rsvp.h1" defaultMessage="RSVP" />
            </h1>
            <div className="rsvp-intro">
              <p>
                <Localized
                  id="rsvp.introText1"
                  defaultMessage="Please fill out the form below to RSVP to our wedding."
                />
              </p>
              <p>
                <Localized
                  id="rsvp.introText2"
                  defaultMessage="
                If you need more time to decide, please let us know and we can follow up with you later. 
                We also understand that plans can change, so if you need to update your RSVP, please let us know as soon as possible."
                />
              </p>
              <div className="rsvp-intro-group">
                <p>
                  <Localized
                    id="rsvp.introText3"
                    defaultMessage="
                If you have any questions, please don't hesitate to reach out to us!"
                  />
                </p>
                <i className="pi pi-heart" />
              </div>
              <p>
                <Localized
                  id="rsvp.introText4"
                  defaultMessage="
               We can't wait to celebrate with you!"
                />
              </p>
            </div>

            <RSVPForm />
          </div>
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default RsvpPage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  .header-text-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-bottom: 4rem;
  }

  h1 {
    z-index: 100;
    color: black;
    font-family: "Boowie", serif;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      top: 50%;
      font-size: 5rem;
    }
  }

  .rsvp-intro {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .rsvp-intro-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
