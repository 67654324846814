import styled from "styled-components";
import { device } from "../../../utils/constants/mediaQueries";
import { LangButtons } from "./LanguageButtons";
import { MobileMenu } from "./MobileMenu";

export const TopBar = () => {
  return (
    <StyledTopBar className="top-bar">
      <div className="top-bar__container">
        <LangButtons />
        <MobileMenu />
      </div>
    </StyledTopBar>
  );
};

export const StyledTopBar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100;
  height: 3rem;
  /* border-bottom: 1px solid var(--primary-100); */
  background-color: rgba(242, 242, 242, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .top-bar {
    &__container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
      padding: 0 1rem;

      @media ${device.md} {
        padding: 0 2rem;
        max-width: 100%;
      }

      @media ${device.xl} {
        padding: 0 3rem;
        max-width: 2500px;
      }
    }
  }
`;
