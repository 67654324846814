import { Toast } from "primereact/toast";
import { ReactNode } from "react";
import Localized from "../../components/intl/Localized";

const life = 4000;

export const showChangesSuccessToastMsg = () => {
  const detail = <Localized id="home." defaultMessage="Changes" />;

  toastRef!.show({
    severity: "success",
    // summary: locStore.id("32eaf7095531").loc("Suksess"),
    detail,
    life,
  });
};

export const showErrorToastMsg = (err: any, summary?: ReactNode) => {
  console.log("Error:", err);

  const detail = (
    <Localized
      id="error.err-msg"
      defaultMessage="We are sorry! An error occured. Please contact Linn or Herman."
    />
  );

  toastRef!.show({
    severity: "error",
    summary,
    detail,
    life,
  });
};

// storing ref globally is generally a bad idea,
// but in this particular usecase it made things easier
let toastRef: Toast | null = null;

export function MyToast() {
  return (
    <Toast
      ref={(ref) => {
        toastRef = ref;
      }}
    />
  );
}

//add in app.tsx:
// <MyToast />
