import { useIntl } from "react-intl";

export interface LocalizedProps {
  id: string;
  defaultMessage: string;
  values?: Record<string, any>;
}
const Localized = (props: LocalizedProps) => {
  const { id, defaultMessage, values } = props;
  const intl = useIntl();
  return <>{intl.formatMessage({ id, defaultMessage }, values)}</>;
};

export default Localized;
