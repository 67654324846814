import { useMemo } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import * as Yup from "yup";

const schemaStrings = {
  fornavnEtternavn: {
    id: "schema.fornavnEtternavn",
    defaultMessage: "Full name is required",
  },
  kontaktinfo: {
    id: "schema.kontaktinfo",
    defaultMessage: "Contact info is required",
  },
  kanKomme: {
    id: "schema.kanKomme",
    defaultMessage: "This field is required",
  },
  velkommenFest: {
    id: "schema.velkommenFest",
    defaultMessage:
      "This field is required when you have answered yes to coming",
  },
  fellesskyss: {
    id: "schema.fellesskyss",
    defaultMessage:
      "This field is required when you have answered yes to coming",
  },
};

export const useRsvpValidationSchema = () => {
  const intl = useIntl();

  // This function converts an error message object to a localized string
  const getErrorString = (errorObj: MessageDescriptor) =>
    intl.formatMessage(errorObj);

  const fornavnEtternavnErrMsg = getErrorString(schemaStrings.fornavnEtternavn);
  const kontaktinfoErrMsg = getErrorString(schemaStrings.kontaktinfo);
  const kanKommeErrMsg = getErrorString(schemaStrings.kanKomme);
  const velkommenFestErrMsg = getErrorString(schemaStrings.velkommenFest);
  const fellesskyssErrMsg = getErrorString(schemaStrings.fellesskyss);

  const RSVP_VALIDATION = useMemo(() => {
    return Yup.object().shape({
      fornavnEtternavn: Yup.string().required(fornavnEtternavnErrMsg),
      fornavnEtternavn2: Yup.string(),
      kontaktinfo: Yup.string().required(kontaktinfoErrMsg),
      kanKomme: Yup.string().required(kanKommeErrMsg),
      velkommenFest: Yup.string().when("kanKomme", (kanKomme: any, schema) =>
        (kanKomme as string) === "ja"
          ? schema.required(velkommenFestErrMsg)
          : schema
      ),

      matallergier: Yup.string(),
      overnattingInfo: Yup.string(),
      fellesskyss: Yup.string().when("kanKomme", (kanKomme: any, schema) =>
        (kanKomme as string) === "ja"
          ? schema.required(fellesskyssErrMsg)
          : schema
      ),
      favorittlat: Yup.string(),
    });
  }, [
    fornavnEtternavnErrMsg,
    kontaktinfoErrMsg,
    kanKommeErrMsg,
    velkommenFestErrMsg,
    fellesskyssErrMsg,
  ]);

  return { RSVP_VALIDATION };
};

export type RsvpFormValues = Yup.InferType<
  ReturnType<typeof useRsvpValidationSchema>["RSVP_VALIDATION"]
>;
