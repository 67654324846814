import styled from "styled-components";
import Localized from "../../components/intl/Localized";
import desktopImage from "../../images/large.jpg";
import logo from "../../images/logo.png";
import { device } from "../../utils/constants/mediaQueries";
import MyButton from "../../components/common/MyButton";
import { CountDown } from "./CountDown";
import { WEDDING_DAY_PATH } from "../../utils/constants/params";
import { useLink } from "../../utils/hooks/useLink";

interface HeroFrontPageProps {}

export const HeroFrontPage = (props: HeroFrontPageProps) => {
  // const scrollToForm = () => {
  //   const formElement = document.getElementById("rsvpForm");
  //   if (formElement) {
  //     formElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const weddingDayLink = useLink(WEDDING_DAY_PATH);

  return (
    <StyledHeroContainer>
      <StyledOverlay>
        <StyledInnerOverlay>
          <StyledLogo src={logo} alt="Logo" />

          <StyledTextContainer>
            <StyleDateRsvp>
              <StyledDate>
                <Localized
                  id="home.date"
                  defaultMessage="20th of September 2024"
                />
              </StyledDate>

              <CountDown />

              <MyButton
                label={
                  <Localized
                    id="home.weddingDayProgram"
                    defaultMessage="Wedding day program"
                  />
                }
                {...weddingDayLink}
              />
            </StyleDateRsvp>
          </StyledTextContainer>
        </StyledInnerOverlay>
      </StyledOverlay>
    </StyledHeroContainer>
  );
};

export const StyledHeroContainer = styled.div`
  background-image: url(${desktopImage});
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 2500px;
  height: 100vh;
  position: relative;
`;

export const StyledOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;

  @media ${device.md} {
    background-color: rgba(242, 242, 242, 0.2);
  }
`;

export const StyledInnerOverlay = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 9rem;

  @media ${device.md} {
    background-color: rgba(255, 255, 255, 0.45);
    width: 800px;
    height: 700px;
    gap: 4rem;
    margin-top: 6rem;
  }
`;

const StyledDate = styled.h3`
  font-size: 1.9rem;
  font-weight: 600;
  text-align: center;

  @media ${device.xs} {
    font-size: 2rem;
  }

  @media ${device.sm} {
    font-size: 2.3rem;
  }
`;

const StyleDateRsvp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;

  @media ${device.sm} {
    gap: 5rem;
    width: 100%;
  }

  .p-button {
    text-transform: uppercase;
    width: 320px;
    margin-top: 2rem;

    @media ${device.xs} {
      width: 100%;
    }

    @media ${device.md} {
      margin-top: 0;
    }
  }
`;

const StyledLogo = styled.img`
  width: 140px;
  height: auto;

  @media ${device.sm} {
    width: 180px;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  @media ${device.md} {
    gap: 0;
  }
`;

// const AnimatedTitle = styled.h1`
//   margin: 1rem 0;
//   padding: 2rem 1.3rem;
//   position: relative;
//   overflow: hidden;
//   white-space: nowrap;
//   animation: handwriting 2s steps(100, end) forwards;
//   font-size: 3.7rem;

//   @media ${device.xs} {
//     font-size: 4rem;
//   }

//   @media ${device.sm} {
//     font-size: 6rem;
//     padding: 3rem;
//   }

//   @keyframes handwriting {
//     0% {
//       width: 0;
//     }
//     100% {
//       width: 100%;
//     }
//   }

//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(
//       to right,
//       transparent 50%,
//       rgba(255, 255, 255, 0) 50%
//     );
//     animation: revealText 4s steps(100, end) forwards;
//   }

//   @keyframes revealText {
//     0% {
//       left: 0;
//     }
//     100% {
//       left: 100%;
//     }
//   }
// `;
