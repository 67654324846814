import { useState } from "react";
import styled from "styled-components";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { NavMenu } from "./NavMenu";

export const MobileMenu = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <StyledMenuBtn
        className="mobile-menu-btn"
        icon="pi pi-bars"
        onClick={() => setOpen(true)}
        aria-controls="popup_menu"
        aria-haspopup
      />

      <StyledSidebar
        className="mobile-side-bar"
        position="right"
        visible={open}
        onHide={() => setOpen(false)}
      >
        <NavMenu />
      </StyledSidebar>
    </>
  );
};

export const StyledSidebar = styled(Sidebar)`
  &.mobile-side-bar {
    width: 255px;
    background-color: var(--background-color-light);
    .p-sidebar-content {
      padding-top: 2rem;
    }
  }
`;

export const StyledMenuBtn = styled(Button)`
  &.mobile-menu-btn {
    background-color: transparent;
    border: none;
    color: var(--primary-500);

    &:hover:not(:disabled) {
      background-color: transparent;
      color: var(--primary-300);
    }
    &:active:not(:disabled) {
      border: none;
    }
    &:focus:not(:disabled) {
      box-shadow: none;
    }
  }
`;
