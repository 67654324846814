const sizes = {
  xs: "450px",
  sm: "768px",
  md: "900px",
  lg: "1200px",
  xl: "1800px",
};

export const device = {
  xs: `(min-width: ${sizes.xs})`,
  sm: `(min-width: ${sizes.sm})`,
  md: `(min-width: ${sizes.md})`,
  lg: `(min-width: ${sizes.lg})`,
  xl: `(min-width: ${sizes.xl})`,
};
