import { Fragment, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { ThemeContext, ThemeContextProps } from "../../../utils/ThemeContext";
import {
  ACCOMMODATION_PATH,
  CASAL_MONTANI_PATH,
  DRESS_CODE_PATH,
  FRONTPAGE_PATH,
  HOW_TO_GET_THERE_PATH,
  QANDA_PATH,
  REAHEARSAL_PATH,
  RSVP_PATH,
  WEDDING_DAY_PATH,
} from "../../../utils/constants/params";
import useLangSearchParam from "../../../utils/hooks/useLangSearchParam";
import { useLink } from "../../../utils/hooks/useLink";
import { CommandBarLink } from "../../common/CommandBarLink";
import Localized from "../../intl/Localized";

export interface NavItem {
  id: number;
  title: JSX.Element;
  subMenu?: NavItem[];
  path?: {
    href: string;
    onClick: (event: any) => void;
  };
  className?: string;
}

export interface NavMenuProps {}

export const NavMenu = (props: NavMenuProps) => {
  const pageLocation = useLocation();

  const { lang } = useLangSearchParam("en");

  const isIt = lang === "it";
  const isNoAndEng = lang === "no" || lang === "en";

  const frontPageLink = useLink(FRONTPAGE_PATH);
  const rehearsalLink = useLink(REAHEARSAL_PATH);
  const weddingDayLink = useLink(WEDDING_DAY_PATH);
  // const wineTourLink = useLink(WINE_TOUR_PATH);
  const dressCodeLink = useLink(DRESS_CODE_PATH);
  const casalMontaniLink = useLink(CASAL_MONTANI_PATH);
  const howToGetThereLink = useLink(HOW_TO_GET_THERE_PATH);
  const accommodationLink = useLink(ACCOMMODATION_PATH);
  const rsvpLink = useLink(RSVP_PATH);
  const qAndALink = useLink(QANDA_PATH);

  const { theme } = useContext(ThemeContext) as ThemeContextProps;

  useEffect(() => {
    document.body.className = `${theme}-theme`;
  }, [theme]);

  const navItems: NavItem[] = [
    {
      id: 1,
      title: <Localized id="menu.home" defaultMessage="Home" />,
      path: frontPageLink,
    },

    {
      id: 7,
      title: <Localized id="menu.qanda" defaultMessage="Q&A" />,
      path: qAndALink,
    },
    {
      id: 2,
      title: <Localized id="menu.program" defaultMessage="Program" />,
      subMenu: [
        {
          id: 2.1,
          title: (
            <Localized id="menu.rehearsal" defaultMessage="Rehearsal party" />
          ),
          path: rehearsalLink,
          className: "sub-menu-item",
        },
        {
          id: 2.2,
          title: (
            <Localized id="menu.weddingDay" defaultMessage="Wedding day" />
          ),
          path: weddingDayLink,
          className: "sub-menu-item",
        },
        // {
        //   id: 2.3,
        //   title: <Localized id="menu.WineTour" defaultMessage="Wine tour" />,
        //   path: wineTourLink,
        //   className: "sub-menu-item",
        // },
      ],
    },
    {
      id: 3,
      title: <Localized id="menu.dresscode" defaultMessage="Dress code" />,
      path: dressCodeLink,
    },
    {
      id: 4,
      title: <Localized id="menu.venue" defaultMessage="Venue and travel" />,
      subMenu: [
        {
          id: 4.1,
          title: (
            <Localized id="menu.casalMontani" defaultMessage="Casal Montani" />
          ),
          path: casalMontaniLink,
          className: "sub-menu-item",
        },
        {
          id: 4.3,
          title: (
            <Localized
              id="menu.howtogetthere"
              defaultMessage="How to get there?"
            />
          ),
          path: howToGetThereLink,
          className: "sub-menu-item",
        },
        {
          id: 4.4,
          title: (
            <Localized id="menu.accommodation" defaultMessage="Accommodation" />
          ),
          path: accommodationLink,
          className: "sub-menu-item",
        },
      ],
    },
    {
      id: 5,
      title: (
        <Localized id="menu.casalMontani" defaultMessage="Casal Montani" />
      ),
      path: casalMontaniLink,
    },
    {
      id: 6,
      title: <Localized id="menu.rsvp" defaultMessage="RSVP" />,
      path: rsvpLink,
    },
  ];

  // Determine if a NavItem should be shown based on its id and the current language
  const shouldShowNavItem = (navItem: NavItem) => {
    if (isIt && navItem.id === 4) {
      // Hide "Venue and travel" for "isIt"
      return false;
    } else if (isNoAndEng && navItem.id === 5) {
      // Hide "Casal Montani" for "isNoAndEng"
      return false;
    }
    return true;
  };

  // Filter navItems based on the current language before rendering
  const filteredNavItems = navItems.filter(shouldShowNavItem);

  return (
    <StyledNavMenu className="nav-menu">
      {filteredNavItems.map((navItem) => {
        const { id, title, path, subMenu, className } = navItem;
        const pathWithoutParamsOrFragments = path?.href.split(/[?#]/)[0];
        const isActive = pageLocation.pathname === pathWithoutParamsOrFragments;

        return (
          <Fragment key={id}>
            <CommandBarLink
              noLink={!!subMenu}
              isActive={isActive}
              className={className}
              {...path}
            >
              {title}
            </CommandBarLink>
            {subMenu && (
              <div className="sub-menu">
                {subMenu.map((subMenuItem) => {
                  const { id, title, path, className } = subMenuItem;
                  const pathWithoutParamsOrFragments =
                    path?.href.split(/[?#]/)[0];
                  const isActive =
                    pageLocation.pathname === pathWithoutParamsOrFragments;
                  return (
                    <CommandBarLink
                      isActive={isActive}
                      key={id}
                      {...path}
                      className={className}
                    >
                      {title}
                    </CommandBarLink>
                  );
                })}
              </div>
            )}
          </Fragment>
        );
      })}
      {/* <MyButton onClick={toggleTheme} label={"Toggle theme"} /> */}
    </StyledNavMenu>
  );
};

const StyledNavMenu = styled.div`
  /* Do not display on small screens.  */
  display: none;
  gap: 15px;
  display: flex;
  flex-direction: column;

  .command-bar-link {
    font-weight: 900;
    font-size: 1.1rem;
    color: var(--primary-500);
  }

  .sub-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
    margin-top: -10px;

    .sub-menu-item {
      font-weight: 900;
      font-size: 0.9rem;
      color: var(--primary-400);
    }
  }
`;
