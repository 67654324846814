import React, { ReactNode } from "react";
import { IntlProvider } from "react-intl";
import EnglishMessages from "../../../locales/eng.json";
import ItalianMessages from "../../../locales/it.json";
import NorwegianMessages from "../../../locales/no.json";
import useLangSearchParam from "../../../utils/hooks/useLangSearchParam";

const messages: Record<string, Record<string, any>> = {
  en: EnglishMessages,
  no: NorwegianMessages,
  it: ItalianMessages,
};

interface IntlProviderWrapperProps {
  children: ReactNode;
}

const IntlProviderWrapper: React.FC<IntlProviderWrapperProps> = ({
  children,
}) => {
  const { lang } = useLangSearchParam("en");

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;
