import { styled } from "styled-components";
import MyAppLayout from "../../components/Layout/Layout/MyAppLayout";
import { SaveTheDateLayout } from "../../components/Layout/Layout/SaveTheDateLayout";
import cheers from "../../images/svg/cheers.svg";
import { device } from "../../utils/constants/mediaQueries";
import { HeroFrontPage } from "./HeroFrontPage";
import { TextSectionFrontPage } from "./TextSectionFrontPage";

interface FrontPageProps {}

const FrontPage = (props: FrontPageProps) => {
  return (
    <MyAppLayout isLandingPage>
      <SaveTheDateLayout>
        <FrontPageContainer>
          <HeroFrontPage />
          <div className="img-container">
            <img
              className="illustration-img"
              src={cheers}
              alt="An illustration of two glasses clinking together in a toast."
            />
          </div>
          <TextSectionFrontPage />
        </FrontPageContainer>
      </SaveTheDateLayout>
    </MyAppLayout>
  );
};

export default FrontPage;

export const FrontPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 100%;
  height: auto;

  .img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 1rem;

    @media ${device.sm} {
      padding: 8rem 0;
    }

    .illustration-img {
      width: 100px;
    }
  }
`;
