export const concatClassnames: (classNames: Array<any>) => string = (
  classNames
) =>
  classNames
    .reduce((acc, className) => {
      if (Array.isArray(className)) {
        className = concatClassnames(className);
      }

      return acc + " " + (className ? className : "");
    }, "")
    .trim();
