import { Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { encode } from "../../utils/Helpers/encode";
import MyButton from "../common/MyButton";
import { FormikDropdown } from "./FormsUI/FormikDropdown";
import { FormikInputText } from "./FormsUI/FormikInputText";
import { FormikTextArea } from "./FormsUI/FormikTextArea";
import { StyledForm } from "./FormsUI/sharedStyles";

import Localized from "../intl/Localized";
import { showErrorToastMsg } from "../../utils/components/ToastMessages";
import { MessageDescriptor, useIntl } from "react-intl";
import {
  RsvpFormValues,
  useRsvpValidationSchema,
} from "./rsvpFormValidationSchema";
import { RsvpDialog } from "../common/RsvpDialog";

const formLabels = {
  fornavnEtternavn: {
    id: "rsvp-form.fornavnEtternavn",
    defaultMessage: "Full name",
  },
  addPerson: {
    id: "rsvp-form.addPerson",
    defaultMessage: "Add person",
  },
  removePerson: {
    id: "rsvp-form.removePerson",
    defaultMessage: "Remove person",
  },
  fornavnEtternavn2: {
    id: "rsvp-form.fornavnEtternavn2",
    defaultMessage: "Full name",
  },
  kontaktinfo: {
    id: "rsvp-form.kontaktinfo",
    defaultMessage: "Contact information (e-mail or phone)",
  },
  kanKomme: {
    id: "rsvp-form.kanKomme",
    defaultMessage: "Can you come to the wedding?",
  },
  velkommenFest: {
    id: "rsvp-form.velkommenFest",
    defaultMessage: "Are you attending the welcome party on September 19th?",
  },
  matallergier: {
    id: "rsvp-form.matallergier",
    defaultMessage:
      "Do you have any food allergies, or is there anything else we should consider?",
  },
  overnattingInfo: {
    id: "rsvp-form.overnattingInfo",
    defaultMessage:
      "If you have booked accommodation, where/in which city are you staying? This information is useful for organizing shared transportation.",
  },
  fellesskyss: {
    id: "rsvp-form.fellesskyss",
    defaultMessage:
      "Are you interested in using the arranged transportation from the wedding?",
  },
  favorittlat: {
    id: "rsvp-form.favorittlat",
    defaultMessage:
      "What's your favorite song, or is there a specific track that's guaranteed to get you on the dance floor?",
  },
  velg: {
    id: "rsvp-form.velg",
    defaultMessage: "Select an option",
  },

  submit: {
    id: "rsvp-form.submit",
    defaultMessage: "Submit",
  },
};

const initialValues: RsvpFormValues = {
  fornavnEtternavn: "",
  fornavnEtternavn2: "",
  kontaktinfo: "",
  kanKomme: "",
  velkommenFest: "",
  matallergier: "",
  overnattingInfo: "",
  fellesskyss: "",
  favorittlat: "",
};

const kanKommeOptions = [
  {
    label: <Localized id="rsvp.yes" defaultMessage="Yes, I will be there" />,
    value: "ja",
  },
  {
    label: <Localized id="rsvp.no" defaultMessage="No, I can't make it" />,
    value: "nei",
  },
];

const partyOptions = [
  {
    label: <Localized id="rsvp.party-yes" defaultMessage="Yes, I'm exited!" />,
    value: "ja",
  },
  {
    label: (
      <Localized id="rsvp.party-no" defaultMessage="No, I can't make it" />
    ),
    value: "nei",
  },
  {
    label: (
      <Localized id="rsvp.party-dunno" defaultMessage="I don't know yet" />
    ),
    value: "vetIkke",
  },
];

const skyssOptions = [
  {
    label: <Localized id="rsvp.skyss-yes" defaultMessage="Yes, please" />,
    value: "ja",
  },
  {
    label: <Localized id="rsvp.skyss-no" defaultMessage="No thank you" />,
    value: "nei",
  },
  {
    label: (
      <Localized id="rsvp.skyss-dunno" defaultMessage="I don't know yet" />
    ),
    value: "vetIkke",
  },
];

export const RSVPForm = () => {
  const [yesVisible, setYesVisible] = useState(false);
  const [noVisible, setNoVisible] = useState(false);
  const [addPerson, setAddPerson] = useState(false);
  const [isPlural, setIsPlural] = useState(false);

  const { RSVP_VALIDATION } = useRsvpValidationSchema();
  const intl = useIntl();

  // This function converts a label object to a localized string
  const getLabelString = (labelObj: MessageDescriptor) =>
    intl.formatMessage(labelObj);

  const submitForm = (
    values: RsvpFormValues,
    helpers: FormikHelpers<RsvpFormValues>
  ) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "rsvp-form", ...values }),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Oh no! Something went wrong.");

        if (values.kanKomme === "ja") {
          setYesVisible(true);
        }

        if (values.kanKomme === "nei") {
          setNoVisible(true);
        }
      })
      .catch((error) => showErrorToastMsg(error))
      .finally(() => {
        helpers.setSubmitting(false);
        helpers.resetForm();
        if (values.fornavnEtternavn2 !== "") {
          setIsPlural(true);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RSVP_VALIDATION}
      onSubmit={submitForm}
    >
      {({ values, errors }) => {
        return (
          <StyledForm name="rsvp-form" data-netlify="true" method="POST">
            <RsvpDialog
              noVisible={noVisible}
              yesVisible={yesVisible}
              setNoVisible={setNoVisible}
              setYesVisible={setYesVisible}
              isPlural={isPlural}
              setIsPlural={setIsPlural}
            />
            <input type="hidden" name="form-name" value="rsvp-form" />
            <div className="form-group">
              <FormikInputText
                label={getLabelString(formLabels.fornavnEtternavn)}
                name="fornavnEtternavn"
                required
              />
              <div className="add-person-btn">
                <MyButton
                  label={
                    addPerson
                      ? getLabelString(formLabels.removePerson)
                      : getLabelString(formLabels.addPerson)
                  }
                  onClick={(e) => {
                    setAddPerson(!addPerson);
                    e.preventDefault();
                  }}
                  text
                  icon={addPerson ? "pi pi-minus" : "pi pi-plus"}
                  iconPos="right"
                />
              </div>
            </div>

            {!!addPerson && (
              <FormikInputText
                label={getLabelString(formLabels.fornavnEtternavn2)}
                name="fornavnEtternavn2"
              />
            )}

            <FormikInputText
              label={getLabelString(formLabels.kontaktinfo)}
              name="kontaktinfo"
              required
            />

            <FormikDropdown
              label={getLabelString(formLabels.kanKomme)}
              placeholder={getLabelString(formLabels.velg)}
              name="kanKomme"
              options={kanKommeOptions}
              required
            />

            {values.kanKomme === "ja" && (
              <>
                <FormikDropdown
                  label={getLabelString(formLabels.velkommenFest)}
                  placeholder={getLabelString(formLabels.velg)}
                  name="velkommenFest"
                  options={partyOptions}
                  required
                />

                <FormikTextArea
                  label={getLabelString(formLabels.matallergier)}
                  name="matallergier"
                />

                <FormikDropdown
                  label={getLabelString(formLabels.fellesskyss)}
                  placeholder={getLabelString(formLabels.velg)}
                  name="fellesskyss"
                  options={skyssOptions}
                  required
                />

                <FormikTextArea
                  label={getLabelString(formLabels.overnattingInfo)}
                  name="overnattingInfo"
                />

                <FormikInputText
                  label={getLabelString(formLabels.favorittlat)}
                  name="favorittlat"
                />
              </>
            )}
            <MyButton
              className="submit-btn"
              type="submit"
              label={getLabelString(formLabels.submit)}
            />
          </StyledForm>
        );
      }}
    </Formik>
  );
};
