import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const useLangSearchParam = (defaultLang: string) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [lang, setLang] = useState<string>(defaultLang); // Set default lang

  useEffect(() => {
    const urlLang = searchParams.get("lang");
    if (urlLang) setLang(urlLang); // Set lang from URL if available
  }, [searchParams]);

  const changeLang = (newLang: string) => {
    setLang(newLang);
    navigate(`?lang=${newLang}`);
  };

  return {
    lang,
    changeLang,
  };
};

export default useLangSearchParam;
