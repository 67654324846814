import { keyframes } from "styled-components";

export const drawLine = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;
