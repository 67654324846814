import { styled } from "styled-components";
import MyAppLayout from "../../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../../components/Layout/Layout/TextPageLayout";
import Localized from "../../../components/intl/Localized";
import castelGandalfo from "../../../images/accomodation/castel-gandalfo-svarthvit.jpg";
import villa from "../../../images/accomodation/villa.jpg";
import { device } from "../../../utils/constants/mediaQueries";
import { Image } from "primereact/image";
import { CommandBarLink } from "../../../components/common/CommandBarLink";
import cheers from "../../../images/svg/cheers.svg";
import houseIcon from "../../../images/svg/house-icon.svg";
import { REAHEARSAL_PATH } from "../../../utils/constants/params";

interface AccommodationPageProps {}

export const AccommodationPage = (props: AccommodationPageProps) => {
  const path = `${REAHEARSAL_PATH}?lang=${
    window.location.search.split("=")[1]
  }`;

  const link1 = {
    href: path,
    onClick: (event: any) => {},
  };

  const link2 = "https://www.airbnb.no/rooms/28134383";

  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="header-text-section">
            <div className="background-img">
              <h1>
                <Localized
                  id="accommodation.h1"
                  defaultMessage="Accommodation"
                />
              </h1>
            </div>
            <div className="text-content-wrapper">
              <section>
                <p>
                  <Localized
                    id="accommodation.p1"
                    defaultMessage="In the area around Casal Montani, there are several places to stay. We have compiled a list of some of the options in 
                    the area."
                  />
                </p>
                <div className="text-section">
                  <h3>
                    <Localized id="accommodation.h3-1" defaultMessage="Rome" />
                  </h3>
                  <p>
                    <Localized
                      id="accommodation.s1-p1"
                      defaultMessage="For those who prefer city life, Rome is a great choice. There are several transport options to Frascati, 
                      but the travel time will naturally be longer compared to staying in areas closer to Frascati."
                    />
                  </p>
                </div>
                <div className="text-section">
                  <h3>
                    <Localized
                      id="accommodation.h3-2"
                      defaultMessage="Frascati"
                    />
                  </h3>
                  <p>
                    <Localized
                      id="accommodation.s1-p2"
                      defaultMessage="As the nearest town to Casal Montani, Frascati offers a range of accommodation options, 
                      from great hotels to charming B&Bs. Frascati is a small but charming town and is the most practical choice for accommodation."
                    />
                  </p>
                </div>
                <div className="text-section">
                  <h3>
                    <Localized
                      id="accommodation.h3-3"
                      defaultMessage="Grottaferrata"
                    />
                  </h3>
                  <p>
                    <Localized
                      id="accommodation.s1-p3"
                      defaultMessage="Text comming"
                    />
                  </p>
                </div>
                <div className="text-section">
                  <h3>
                    <Localized
                      id="accommodation.h3-4"
                      defaultMessage="Marino"
                    />
                  </h3>
                  <p>
                    <Localized
                      id="accommodation.s1-p4"
                      defaultMessage="Text comming"
                    />
                  </p>
                </div>
                <div className="text-section">
                  <h3>
                    <Localized
                      id="accommodation.h3-5"
                      defaultMessage="Castel Gandolfo"
                    />
                  </h3>
                  <p>
                    <Localized
                      id="accommodation.s1-p5"
                      defaultMessage="Known as the summer residence of the Pope, this town is located on the shores of Lake Albano. 
                      It offers a picturesque setting and is perfect for those who want to combine cultural and scenic experiences."
                    />
                  </p>
                </div>
                <div className="text-section">
                  <h3>
                    <Localized
                      id="accommodation.h3-6"
                      defaultMessage="Rocca di papa"
                    />
                  </h3>
                  <p>
                    <Localized
                      id="accommodation.s1-p6"
                      defaultMessage="This small town is located in the Castelli Romani hills and is known for its fantastic views and tranquil atmosphere. 
                      This is also where we will be staying in a villa with some friends the week before the wedding."
                    />
                  </p>
                </div>
              </section>

              <section>
                <h2>
                  <Localized
                    id="accommodation.h2-1"
                    defaultMessage="Where are we staying?"
                  />
                </h2>
                <div className="villa-section">
                  <div className="text-section">
                    <p>
                      <Localized
                        id="accommodation.s2-p1"
                        defaultMessage="We have rented a charming villa in Rocca Di Papa, located just 20 minutes from Casal Montani. 
                      Our goal was to find a perfect place for gatherings where we can invite our guests to spend time together before the wedding. 
                      We have rented the villa from Monday, September 16, and it will serve as our base in the preparations before the wedding day. 
                      It is also here that we are planning to have a “welcome party” on Thursday, the day before the wedding."
                      />
                    </p>

                    <CommandBarLink pageLink {...link1}>
                      <img
                        className="wine-icon"
                        src={cheers}
                        alt="An illustration of two glasses clinking together in a toast."
                      />
                      <Localized
                        id="accommodation.link1"
                        defaultMessage="Read more about the party here!"
                      />
                    </CommandBarLink>

                    <p>
                      <Localized
                        id="accommodation.s2-p2"
                        defaultMessage="We have rented a charming villa in Rocca Di Papa, located just 20 minutes from Casal Montani. 
                      Our goal was to find a perfect place for gatherings where we can invite our guests to spend time together before the wedding. 
                      We have rented the villa from Monday, September 16, and it will serve as our base in the preparations before the wedding day. 
                      It is also here that we are planning to have a “welcome party” on Thursday, the day before the wedding."
                      />
                    </p>
                  </div>
                  <div className="villa-section-img">
                    <Image
                      src={villa}
                      alt="A charming peach-colored villa with balconies, surrounded by lush greenery and a cobblestone path under a partly cloudy sky."
                      preview
                    />
                    <a
                      href={link2}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="link"
                    >
                      <img
                        className="house-icon"
                        src={houseIcon}
                        alt="Illustration of a house"
                      />
                      <Localized
                        id="accommodation.link2"
                        defaultMessage="Link to our Airbnb villa"
                      />
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default AccommodationPage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  .background-img {
    background-image: url(${castelGandalfo});
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 150px;
    position: relative;
    margin-bottom: 2rem;

    @media ${device.sm} {
      height: 250px;
    }
  }

  .background-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White with 0.5 opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  h1 {
    z-index: 100;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      font-size: 5rem;
    }
  }

  .heading {
    font-weight: 900;
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1.7rem;

    @media ${device.md} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.1rem;
    margin-bottom: -1rem;
    @media ${device.md} {
      font-size: 1.2rem;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .text-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .text-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @media ${device.md} {
      gap: 5rem;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
  }

  li {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .link-with-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      opacity: 0.6;
    }
  }

  .villa-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media ${device.sm} {
      flex-direction: row;
    }
  }

  .villa-section-img {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  img {
    width: 100%;

    @media ${device.sm} {
      width: 300px;
    }
  }

  .wine-icon {
    width: 30px;
    height: 30px;
  }

  .link {
    color: var(--primary-500);
    font-weight: 900;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.5rem;

    &:hover {
      opacity: 0.6;
    }
  }

  .house-icon {
    width: 25px;
    height: 25px;
  }
`;
