import { ErrorMessage, Field, FieldProps } from "formik";
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";
import styled from "styled-components";
import { concatClassnames } from "../../../utils/Helpers/concatClassNames";
import { StyledFormikField } from "./sharedStyles";

interface FormikTextAreaProps extends InputTextareaProps {
  label: string;
  name: string;
  required?: boolean;
}

export const FormikTextArea = (props: FormikTextAreaProps) => {
  const { label, disabled, value, name, required, ...rest } = props;

  return (
    <StyledFormikField
      className={concatClassnames([
        "formik-text-area-container",
        disabled && "field--disabled",
      ])}
    >
      <label
        htmlFor={name}
        onClick={() => {
          console.log(props);
        }}
      >
        {required ? label + "*" : label}
      </label>
      <Field name={name}>
        {(props: FieldProps<FormikTextAreaProps["value"]>) => {
          const { field } = props;

          return (
            <div className="input-error-container">
              <StyledTextArea
                id={name}
                disabled={disabled}
                {...field}
                {...rest}
                value={field.value ?? ""}
              />

              <ErrorMessage
                className="input-error-container__error-msg"
                component="div"
                name={name}
              />
            </div>
          );
        }}
      </Field>
    </StyledFormikField>
  );
};

export const StyledTextArea = styled(InputTextarea)`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
`;
