import { Dialog } from "primereact/dialog";
import { styled } from "styled-components";
import { device } from "../../utils/constants/mediaQueries";
import Localized from "../intl/Localized";

interface RsvpDialogProps {
  noVisible: boolean;
  yesVisible: boolean;
  setNoVisible: (visible: boolean) => void;
  setYesVisible: (visible: boolean) => void;
  isPlural: boolean;
  setIsPlural: (isPlural: boolean) => void;
}

export const RsvpDialog = (props: RsvpDialogProps) => {
  const {
    noVisible,
    yesVisible,
    setNoVisible,
    setYesVisible,
    isPlural: plural,
    setIsPlural,
  } = props;

  return (
    <>
      {noVisible && (
        <StyledRsvpDialog
          visible={noVisible}
          onHide={() => {
            setIsPlural(false);
            setNoVisible(false);
          }}
        >
          <h3>
            {!plural ? (
              <Localized
                id="rsvpDialog.noH3"
                defaultMessage="Thank you for letting us know!"
              />
            ) : (
              <Localized
                id="rsvpDialog.noH3-plural"
                defaultMessage="Thank you for letting us know!"
              />
            )}
          </h3>
          <i className="pi pi-heart" />
          <p>
            {!plural ? (
              <Localized
                id="rsvpDialog.noText"
                defaultMessage="We're sorry to hear that you can't make it. 
                    While we will miss having you there, 
                    we completely understand that not everyone can attend.
                    If you have any questions or if anything changes, 
                    please don't hesitate to reach out. We hope to see you soon!"
              />
            ) : (
              <Localized
                id="rsvpDialog.noText-plural"
                defaultMessage="We're sorry to hear that you can't make it. 
                    While we will miss having you guys there, 
                    we completely understand that not everyone can attend.
                    If you have any questions or if anything changes, 
                    please don't hesitate to reach out. We hope to see you soon!"
              />
            )}
          </p>
        </StyledRsvpDialog>
      )}
      {yesVisible && (
        <StyledRsvpDialog
          visible={yesVisible}
          onHide={() => {
            setYesVisible(false);
            setIsPlural(false);
          }}
        >
          <h3>
            {!plural ? (
              <Localized
                id="rsvpDialog.yesH3"
                defaultMessage="Thank you so much for coming!"
              />
            ) : (
              <Localized
                id="rsvpDialog.yesH3-plural"
                defaultMessage="Thank you so much for coming!"
              />
            )}
          </h3>
          <i className="pi pi-heart" />
          <p>
            {!plural ? (
              <Localized
                id="rsvpDialog.yesText"
                defaultMessage="We are looking forward to see you at the wedding 
              and can't wait to celebrate with you! Keep an eye on the website 
              for updates leading up to the wedding. If you have any questions 
              or if anything changes, please feel free to reach out."
              />
            ) : (
              <Localized
                id="rsvpDialog.yesText-plural"
                defaultMessage="We are looking forward to see you at the wedding 
              and can't wait to celebrate with you guys! Keep an eye on the website 
              for updates leading up to the wedding. If you have any questions 
              or if anything changes, please feel free to reach out."
              />
            )}
          </p>
        </StyledRsvpDialog>
      )}
    </>
  );
};
const StyledRsvpDialog = styled(Dialog)`
  &.p-dialog {
    width: 90vw;
    min-height: 250px;
    background-color: white;

    @media ${device.lg} {
      width: 60vw;
    }

    .p-dialog-header {
      padding: 0.7rem 1.5rem;
    }

    .p-dialog-content {
      padding: 0.5rem 2rem 3rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      @media ${device.sm} {
        padding: 0.7rem 4rem 3rem 4rem;
      }
    }

    h3 {
      font-family: "Cormorant", serif;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
`;
