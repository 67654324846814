import { ErrorMessage, Field, FieldProps } from "formik";
import { InputText, InputTextProps } from "primereact/inputtext";
import styled from "styled-components";
import { concatClassnames } from "../../../utils/Helpers/concatClassNames";
import { Modify } from "../../../utils/Helpers/typescript/Modify";
import { StyledFormikField } from "./sharedStyles";

type FormikInputTextProps = Modify<
  InputTextProps,
  {
    label: string;
    name: string;
    onChange?: (e: any, form: any) => void;
    required?: boolean;
  }
>;

export const FormikInputText = (props: FormikInputTextProps) => {
  const { label, disabled, name, onChange, value, required, ...rest } = props;

  return (
    <StyledFormikField
      className={concatClassnames([
        "formik-input-text-container",
        disabled && "field--disabled",
      ])}
    >
      <label
        htmlFor={name}
        onClick={() => {
          console.log(props);
        }}
      >
        {required ? label + "*" : label}
      </label>
      <Field name={name}>
        {(props: FieldProps<FormikInputTextProps["value"]>) => {
          const { field, form } = props;

          return (
            <div className="input-error-container">
              <StyledInputText
                id={name}
                {...field}
                {...rest}
                value={field.value ?? ""}
                disabled={disabled}
                onChange={(e) => {
                  if (onChange) {
                    onChange(e, form);
                  } else {
                    field.onChange(e);
                  }
                }}
              />
              <ErrorMessage
                className="input-error-container__error-msg"
                component="div"
                name={name}
              />
            </div>
          );
        }}
      </Field>
    </StyledFormikField>
  );
};

export const StyledInputText = styled(InputText)``;
