import { styled } from "styled-components";
import MyAppLayout from "../../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../../components/Layout/Layout/TextPageLayout";
import Localized from "../../../components/intl/Localized";
import roses from "../../../images/program/roses.png";
import { device } from "../../../utils/constants/mediaQueries";
import timelineEng from "../../../images/timeline/TimelineEng.png";
import timelineNor from "../../../images/timeline/TimelineNor.png";
import timelineIt from "../../../images/timeline/TimelineIt.png";
import useLangSearchParam from "../../../utils/hooks/useLangSearchParam";

interface WeddingDayPageProps {}

export const WeddingDayPage = (props: WeddingDayPageProps) => {
  const { lang } = useLangSearchParam("en");

  const isIt = lang === "it";
  const isEng = lang === "en";
  const isNo = lang === "no";

  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="background-img">
            <h1>
              <Localized id="weddingday.h1" defaultMessage="Wedding day" />
            </h1>
          </div>

          <section className="text-section">
            <p>
              <Localized
                id="weddingday.p1"
                defaultMessage="Our wedding day is planned as an afternoon wedding, with the ceremony starting at 17:00. The ceremony will take place among the vineyards at Casal Montani and will be a symbolic ceremony lasting around 20 minutes. Afterwards, we invite you to a pleasant welcome drink with finger foods and refreshments."
              />
            </p>
            <p>
              <Localized
                id="weddingday.p2"
                defaultMessage="Then follows a delicious antipasti buffet in the backyard, where we arrange for mingling and free seating. We also want you to get to know the wonderful vineyard we have chosen for our wedding. Therefore, we give our guests a gift in the form of a wine tour in the historic cellar of Casal Montani. You will be taken in groups to see where the wine is stored, learn a bit about the history, and taste the wine."
              />
            </p>
            <p>
              <Localized
                id="weddingday.p3"
                defaultMessage="At 20:00, there will be dinner in the garden, followed by wedding cake and a dessert buffet. The rest of the evening will feature singing, dancing, and DJ sets from several of our talented friends."
              />
            </p>
            <p>
              <Localized
                id="weddingday.p4"
                defaultMessage="When the party is over, we have arranged buses to transport you safely back to where you have booked your accommodation. One bus will leave at 01:00 for those who wish to leave a bit earlier, and two buses will leave at 02:30 for those who want to stay until the end."
              />
            </p>
          </section>

          <section>
            {isEng && (
              <img
                className="timeline"
                src={timelineEng}
                alt="The wedding day timeline."
              />
            )}
            {isNo && (
              <img
                className="timeline"
                src={timelineNor}
                alt="The wedding day timeline."
              />
            )}
            {isIt && (
              <img
                className="timeline"
                src={timelineIt}
                alt="The wedding day timeline."
              />
            )}
          </section>
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default WeddingDayPage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding-bottom: 3rem;

  .background-img {
    background-image: url(${roses});
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 200px;
    position: relative;

    @media ${device.sm} {
      height: 300px;
      margin-bottom: 2rem;
    }
  }

  .background-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White with 0.5 opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  h1 {
    z-index: 100;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      top: 50%;
      font-size: 5rem;
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .illustration-img {
    width: 80px;
  }

  .timeline {
    width: 350px;
    max-width: 350px;

    @media ${device.xs} {
      width: 400px;
      max-width: 400px;
    }

    @media ${device.sm} {
      width: 100%;
      max-width: 100%;
    }
  }
`;
