export enum language {
  en = "en",
  it = "it",
  nor = "nor",
}

export const FRONTPAGE_PATH = "/";
export const REAHEARSAL_PATH = "/program/rehearsal";
export const WEDDING_DAY_PATH = "/program/wedding-day";
export const WINE_TOUR_PATH = "/program/wine-tour";
export const DRESS_CODE_PATH = "/dress-code";
export const CASAL_MONTANI_PATH = "/venue/casal-montani";
export const HOW_TO_GET_THERE_PATH = "/venue/getting-there";
export const ACCOMMODATION_PATH = "/venue/accommodation";
export const QANDA_PATH = "/questions";
export const RSVP_PATH = "/rsvp";

export const Params = {
  language: {
    key: "lang",
    values: language,
    persistForPaths: [
      FRONTPAGE_PATH,
      REAHEARSAL_PATH,
      WEDDING_DAY_PATH,
      WINE_TOUR_PATH,
      DRESS_CODE_PATH,
      CASAL_MONTANI_PATH,
      HOW_TO_GET_THERE_PATH,
      ACCOMMODATION_PATH,
      QANDA_PATH,
      RSVP_PATH,
    ],
    persistAllways: false,
  },
};
