import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./Fonts/fonts.css";
import "./styles/theme/my-theme.css";
import "./styles/theme/theme.css";
import { useContext, useEffect } from "react";
import IntlProviderWrapper from "./components/Layout/Layout/IntlProviderWrapper";
import FrontPage from "./pages/FrontPage";
import { ThemeContext, ThemeContextProps } from "./utils/ThemeContext";
import AccommodationPage from "./pages/VenueAndTravel/Accommodation";
import CasalMontaniPage from "./pages/VenueAndTravel/CasalMontani";
import HowToGetTherePage from "./pages/VenueAndTravel/HowToGetThere";
import { ScrollToTop } from "./utils/Helpers/ScrollToTop";
import { WeddingDayPage } from "./pages/Program/WeddingDay";
import RsvpPage from "./pages/Rsvp";
import { MyToast } from "./utils/components/ToastMessages";
import DresscodePage from "./pages/Dresscode";
import {
  ACCOMMODATION_PATH,
  CASAL_MONTANI_PATH,
  DRESS_CODE_PATH,
  FRONTPAGE_PATH,
  HOW_TO_GET_THERE_PATH,
  REAHEARSAL_PATH,
  RSVP_PATH,
  WEDDING_DAY_PATH,
} from "./utils/constants/params";
import RehearsalPage from "./pages/Program/Rehearsal";
import { QandAPage } from "./pages/QandA";

function App() {
  const { theme } = useContext(ThemeContext) as ThemeContextProps;

  useEffect(() => {
    document.body.className = `${theme}-theme`;
  }, [theme]);

  return (
    <div>
      <Router>
        <IntlProviderWrapper>
          <ScrollToTop>
            <MyToast />
            <Routes>
              <Route path={FRONTPAGE_PATH} element={<FrontPage />} />
              <Route path={REAHEARSAL_PATH} element={<RehearsalPage />} />
              <Route path={WEDDING_DAY_PATH} element={<WeddingDayPage />} />
              {/* <Route path="/program/wine-tour" element={<WineTourPage />} /> */}
              <Route path={DRESS_CODE_PATH} element={<DresscodePage />} />
              <Route path={CASAL_MONTANI_PATH} element={<CasalMontaniPage />} />

              <Route
                path={HOW_TO_GET_THERE_PATH}
                element={<HowToGetTherePage />}
              />
              <Route
                path={ACCOMMODATION_PATH}
                element={<AccommodationPage />}
              />

              <Route path={RSVP_PATH} element={<RsvpPage />} />
              <Route path="/questions" element={<QandAPage />} />
            </Routes>
          </ScrollToTop>
        </IntlProviderWrapper>
      </Router>
    </div>
  );
}

export default App;
