import { styled } from "styled-components";
import MyAppLayout from "../../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../../components/Layout/Layout/TextPageLayout";
import Localized from "../../../components/intl/Localized";
import { device } from "../../../utils/constants/mediaQueries";
import frascati from "../../../images/frascati/frascati-svarthvit.jpg";

interface HowToGetTherePageProps {}

export const HowToGetTherePage = (props: HowToGetTherePageProps) => {
  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="background-img">
            <h1>
              <Localized
                id="howToGetThere.h1"
                defaultMessage="How to get there?"
              />
            </h1>
          </div>
          <div className="text-content-wrapper">
            <section>
              <h2>
                <Localized id="howToGetThere.h2-1" defaultMessage="Airports" />
              </h2>
              <div className="text-section">
                <h3>
                  <Localized
                    id="howToGetThere.h3-1"
                    defaultMessage="Fiumicino Airport (FCO)"
                  />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s1-p1"
                    defaultMessage="Fiumicino Airport, also known as Leonardo da Vinci International Airport,
                 is the primary airport near Rome, often chosen by travelers to the city. 
                 It is located about 40 kilometers from Frascati, a journey that typically takes an hour 
                 by car, depending on traffic conditions. For those looking to explore Rome, the distance 
                 from Fiumicino to the city center is around 30 kilometers, usually a 45-minute drive."
                  />
                </p>
              </div>
              <div className="text-section">
                <h3>
                  <Localized
                    id="howToGetThere.h3-2"
                    defaultMessage="Ciampino Airport (CIA)"
                  />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s1-p2"
                    defaultMessage="Ciampino Airport, a smaller but more centrally located airport, 
                  is closer to both Rome and Frascati. The distance to Frascati is just 14 kilometers, 
                  which is about a 15-minute drive. 
                  Due to its size, Ciampino has fewer flight routes compared to Fiumicino."
                  />
                </p>
              </div>
            </section>

            <section>
              <h2>
                <Localized
                  id="howToGetThere.h2-2"
                  defaultMessage="Transportation"
                />
              </h2>
              <p>
                <Localized
                  id="howToGetThere.s2-p1"
                  defaultMessage="For transportation from Fiumicino and Ciampino airport to Frascati or Rome, 
                  there are primarily two options: renting a car or taking the train. 
                  For a smooth and stress-free journey to the wedding and the surrounding areas, it is 
                  advisable to plan transportation in advance."
                />
              </p>
              <div className="text-section">
                <h3>
                  <Localized
                    id="howToGetThere.h3-3"
                    defaultMessage="Rental car"
                  />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s2-p2"
                    defaultMessage="Renting a car offers flexibility and comfort, ideal for trips to Frascati and the 
                    surrounding areas. 
                    With your own car, you can travel independently and explore the area at your own pace. 
                    Based on experience, it is most practical to rent a car either from the airport or from Termini, 
                    Rome's main train station."
                  />
                </p>
                <p>
                  <Localized
                    id="howToGetThere.s2-p3"
                    defaultMessage="Both driving and parking in Rome can be challenging. For those planning to stay in the 
                    city center, we recommend the Prati area. Here, street parking is more accessible, and many hotels and 
                    Airbnbs offer private parking. The Prati area also provides easy access to the city's attractions with 
                    several metro stations within walking distance. For those preferring to stay in the heart of the city, 
                    it is advisable to use a parking garage for the car."
                  />
                </p>
              </div>
              <div className="text-section">
                <h3>
                  <Localized id="howToGetThere.h3-4" defaultMessage="Train" />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s2-p4"
                    defaultMessage="From Fiumicino Airport, there are direct trains to Roma Termini, the city's main train station. 
                    From Roma Termini, you can take a regional train to Frascati, which takes about 30 minutes."
                  />
                </p>
                <p>
                  <Localized
                    id="howToGetThere.s2-p5"
                    defaultMessage="From Ciampino Airport, a quick 15-minute train ride can take you to Rome. 
                    Additionally, you can reach Frascati by train from Ciampino Airport with a simple transfer. 
                    The journey involves taking a train to the Ciampino main station and then connecting to Frascati. 
                    This trip takes between 30 minutes to 1 hour, depending on the connection time at Ciampino main station"
                  />
                </p>
                <p>
                  <Localized
                    id="howToGetThere.s2-p6"
                    defaultMessage="Please be aware that additional transportation, such as a taxi or bus, may be required to reach 
                    Casal Montani from the train station. 
                    More information on post-wedding-celebration transportation will be provided."
                  />
                </p>
              </div>
            </section>

            <section>
              <h2>
                <Localized
                  id="howToGetThere.h2-3"
                  defaultMessage="How to reach Casal Montani on the wedding day?"
                />
              </h2>
              <p>
                <Localized
                  id="howToGetThere.s3-p1"
                  defaultMessage="On the wedding day, we encourage guests to arrange their own transportation to Casal Montani. 
                  However, after the celebration ends, we will arrange transportation to ensure a safe journey home."
                />
              </p>
              <div className="text-section">
                <h3>
                  <Localized
                    id="howToGetThere.h3-5"
                    defaultMessage="From Frascati and surrounding areas"
                  />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s3-p2"
                    defaultMessage="The best way to travel from Frascati and nearby areas is to take a taxi or Uber. 
                    Alternatively, guests can drive themselves if they do not plan to consume alcohol. Parking is available at Casal Montani."
                  />
                </p>
              </div>
              <div className="text-section">
                <h3>
                  <Localized
                    id="howToGetThere.h3-6"
                    defaultMessage="From Rome"
                  />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s3-p3"
                    defaultMessage="Guests traveling from Rome have several options."
                  />
                </p>
                <ul>
                  <li>
                    <Localized
                      id="howToGetThere.s3-li1"
                      defaultMessage="Drive yourself or use a taxi/Uber for the entire journey."
                    />
                  </li>
                  <li>
                    <Localized
                      id="howToGetThere.s3-li2"
                      defaultMessage="Take a train from Roma Termini to Frascati and then use a taxi/Uber to Casal Montani."
                    />
                  </li>
                  <li>
                    <Localized
                      id="howToGetThere.s3-li3"
                      defaultMessage="Take the Metro to Anagnina station, and then take a taxi/Uber."
                    />
                  </li>
                </ul>
              </div>
              <div className="text-section">
                <h3>
                  <Localized
                    id="howToGetThere.h3-7"
                    defaultMessage="Important considerations"
                  />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s3-p4"
                    defaultMessage="We suggest planning for extra travel time, especially for those coming from Rome or 
                    driving on the autostrada, to account for unexpected delays. Traffic jams, often due to rush hour, 
                    accidents, or road work, can significantly delay the journey. We have experienced being stuck on the 
                    autostrada due to accidents, including one time when we were heading to a wedding and arrived late for 
                    the ceremony! Therefore, plan with a good time margin to avoid delays."
                  />
                </p>
              </div>
              <div className="text-section">
                <h3>
                  <Localized
                    id="howToGetThere.h3-8"
                    defaultMessage="Transportation after the party"
                  />
                </h3>
                <p>
                  <Localized
                    id="howToGetThere.s3-p5"
                    defaultMessage="We are in the process of finding the best solution for transportation after the party. 
                    Our considerations include either ensuring that taxis or Ubers are available at regular intervals after 01:00, 
                    or organizing a bus that will first go to Frascati and then towards Rocca di Papa."
                  />
                </p>

                <p>
                  <Localized
                    id="howToGetThere.s3-p6"
                    defaultMessage="To determine the best route for the bus, we first need to know where our guests will be staying, 
                    so we will include a section in the upcoming RSVP form to collect this information. Although nothing is finalized yet, 
                    we will ensure that everyone gets home safely. We will provide more detailed information as soon as we have a clear plan."
                  />
                </p>
              </div>
            </section>
          </div>
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default HowToGetTherePage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .background-img {
    background-image: url(${frascati});
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 150px;
    position: relative;
    margin-bottom: 2rem;

    @media ${device.sm} {
      height: 250px;
    }
  }

  .background-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White with 0.5 opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  h1 {
    z-index: 100;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      font-size: 5rem;
    }
  }

  .heading {
    font-weight: 900;
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1.7rem;

    @media ${device.md} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.1rem;
    margin-bottom: -1rem;
    @media ${device.md} {
      font-size: 1.2rem;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-left: 2px solid var(--primary-500);
    padding: 1.5rem 0 1.5rem 1rem;

    @media ${device.md} {
      padding: 2rem 0 2rem 2rem;
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .text-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @media ${device.md} {
      gap: 5rem;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
  }

  li {
    font-size: 1.1rem;
    font-weight: 600;
  }
`;
