import { useRef, useState } from "react";
import { Galleria } from "primereact/galleria";
import { styled } from "styled-components";
import { CasalMontaniGalleriaProps } from "./CasalMontaniGalleria";
import { Image, images } from "./CasalMontaniImages";

export const CasalMontaniGalleriaSmallerImages = (
  props: CasalMontaniGalleriaProps
) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const galleria = useRef<Galleria | any>(null);

  const itemTemplate = (image: Image) => {
    return (
      <img
        src={image.itemImageSrc}
        alt={image.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (image: Image) => {
    return (
      <img
        src={image.thumbnailImageSrc}
        alt={image.alt}
        style={{ display: "block", width: "60px", height: "auto" }}
      />
    );
  };

  return (
    <div className="card flex justify-content-center">
      <Galleria
        ref={galleria}
        value={images}
        numVisible={7}
        style={{ maxWidth: "850px" }}
        activeIndex={activeIndex}
        onItemChange={(e) => setActiveIndex(e.index)}
        circular
        fullScreen
        showItemNavigators
        showThumbnails={false}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
      <StyledGalleryContainer>
        {images &&
          images.map((image, index) => {
            let imgEl = (
              <img
                src={image.thumbnailImageSrc}
                alt={image.alt}
                onClick={() => {
                  setActiveIndex(index);
                  galleria.current.show();
                }}
              />
            );
            return <div key={index}>{imgEl}</div>;
          })}
      </StyledGalleryContainer>
    </div>
  );
};
const StyledGalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;

  img {
    cursor: pointer;
    width: 200px;
    height: auto;
  }
`;
