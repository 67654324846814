import casalMontani1 from "../../images/casalMontani/casal-montani1.jpg";
import casalMontani2 from "../../images/casalMontani/casal-montani2.jpg";
import casalMontani3 from "../../images/casalMontani/casal-montani3.jpg";
import casalMontani4 from "../../images/casalMontani/casal-montani4.jpg";
import casalMontani5 from "../../images/casalMontani/casal-montani5.jpg";
import casalMontani6 from "../../images/casalMontani/casal-montani6.jpg";
import casalMontani7 from "../../images/casalMontani/casal-montani7.jpg";
import casalMontani8 from "../../images/casalMontani/casal-montani8.jpg";
import casalMontani10 from "../../images/casalMontani/casal-montani10.jpg";

export interface Image {
  itemImageSrc: string;
  thumbnailImageSrc: string;
  alt: string;
}

export const images: Image[] = [
  {
    itemImageSrc: casalMontani1,
    thumbnailImageSrc: casalMontani1,
    alt: " A serene courtyard with a clear blue sky, showcasing a well-preserved building with an orange façade. Lush green trees and a neatly paved cobblestone ground add to the tranquil atmosphere.",
  },
  {
    itemImageSrc: casalMontani4,
    thumbnailImageSrc: casalMontani4,
    alt: "Herman walking away on a cobblestone path, surrounded by verdant garden beds and a wooden fence, overlooking the vines of Casal Montani.",
  },
  {
    itemImageSrc: casalMontani10,
    thumbnailImageSrc: casalMontani10,
    alt: "An old wooden cart under the shade of tall pine trees, situated on a grassy field with the clear blue sky in the background, evoking a rustic and peaceful countryside scene.",
  },
  {
    itemImageSrc: casalMontani2,
    thumbnailImageSrc: casalMontani2,
    alt: " A peaceful country road lined with towering pine trees, leading the eye towards a soft, hilly landscape under a cloudy sky, evoking a sense of calm and solitude",
  },
  {
    itemImageSrc: casalMontani3,
    thumbnailImageSrc: casalMontani3,
    alt: " Inside a rustic, historical building with stone walls and a wooden beam ceiling, highlighting a unique indoor pool reflecting the light from the open door and overhead lights.",
  },

  {
    itemImageSrc: casalMontani5,
    thumbnailImageSrc: casalMontani5,
    alt: " Close-up of a wine bottle being tilted, with a blurred background showing stacked wine bottles in a cellar, highlighting the rich color of the wine against the dark bottles.",
  },
  {
    itemImageSrc: casalMontani6,
    thumbnailImageSrc: casalMontani6,
    alt: "A traditional wine cellar with arched stone walls and ceiling, illuminated by hanging lights, showcasing wine-making equipment and storage bottles.",
  },
  {
    itemImageSrc: casalMontani7,
    thumbnailImageSrc: casalMontani7,
    alt: "A lush, green garden with neatly trimmed grass, framed by majestic trees and a view of a vineyard in the distance under an overcast sky, conveying a feeling of tranquility.",
  },
  {
    itemImageSrc: casalMontani8,
    thumbnailImageSrc: casalMontani8,
    alt: "A wooden deck with a single tree trunk in the center, overlooking a fenced area with a pastoral landscape and mountains under a gloomy sky, suggesting introspection and tranquility.",
  },
];
