import {
  useHref,
  useLinkClickHandler,
  useLocation,
  useSearchParams,
  matchPath,
} from "react-router-dom";

import { Params } from "../constants/params";

/**
 * Single page links adapted to our needs
 * @param path path to link to
 * @param _searchParams search params for the url
 * @param state state stored in window.history.state
 */
export function useLink(
  path: string | null,
  _searchParams: { [key: string]: any } | null = {},
  state: { [key: string]: any } | null = {}
) {
  let location = useLocation();
  if (!path) {
    path = location.pathname;
  }
  const [searchParams] = useSearchParams();

  const ss = new URLSearchParams(searchParams);
  const searchParamKeysToKeepByDefault = Object.values(Params).reduce(
    (acc, param) => {
      const persistForPath = !!param.persistForPaths.find((p) => {
        return matchPath(p, path!);
      });

      if (persistForPath || param.persistAllways) {
        acc[param.key] = ss.get(param.key);
      }
      return acc;
    },
    {} as { [key: string]: string | null }
  );

  const newSS = new URLSearchParams();
  Object.entries({
    ...searchParamKeysToKeepByDefault,
    ..._searchParams,
  }).forEach(([key, value]) => {
    if (value) {
      newSS.set(key, value);
    }
  });

  let href = useHref(`${path}?${newSS.toString()}`);
  let onClick = useLinkClickHandler(href, { state });

  return {
    href,
    onClick: (event: any) => {
      onClick?.(event);
      if (!event?.defaultPrevented) {
        onClick(event);
      }
    },
  };
}
