import { styled } from "styled-components";
import Localized from "../../components/intl/Localized";
import { device } from "../../utils/constants/mediaQueries";
import {
  REAHEARSAL_PATH,
  WEDDING_DAY_PATH,
} from "../../utils/constants/params";
import { useLink } from "../../utils/hooks/useLink";

interface TextSectionFrontPageProps {}

export const TextSectionFrontPage = (props: TextSectionFrontPageProps) => {
  const welcomePartyLink = useLink(REAHEARSAL_PATH);
  const weddingCeremonyLink = useLink(WEDDING_DAY_PATH);

  return (
    <TextSectionContainer>
      <div id="textSection" className="text-container">
        <div className="border">
          <div className="inner-text-container">
            <h2>
              <Localized
                id="home.h2"
                defaultMessage="Welcome to our wedding website!"
              />
            </h2>
            <p>
              <Localized
                id="home.text1"
                defaultMessage="We are so excited to share our special day with you, and are so exited that so many of you can make it to our wedding! Here you will find all the information you need to know about the wedding, including the program, venue, and how to get there. We will update the website continuously with new information, so make sure to check it regularly!"
              />
            </p>

            <p>
              <Localized
                id="home.text2"
                defaultMessage="We can't wait to celebrate with you!"
              />
            </p>

            <div className="important-list-section">
              <h4>
                <Localized id="home.h4" defaultMessage="Important dates" />
              </h4>

              <ul>
                <li role="link" {...welcomePartyLink}>
                  <Localized
                    id="home.li1"
                    defaultMessage="Welcome party - Thursday, 19th of September 2024 at 12:00"
                  />
                  <i className="pi pi-heart" />
                </li>
                <li role="link" {...weddingCeremonyLink}>
                  <Localized
                    id="home.li2"
                    defaultMessage="Wedding ceremony - Friday, 20th of September 2024 at 17:00"
                  />
                  <i className="pi pi-heart" />
                </li>
              </ul>
            </div>

            <p>
              <Localized
                id="home.text3"
                defaultMessage="Should you have any questions, please don't hesitate to get in touch with us!"
              />
            </p>
          </div>
        </div>

        <div className="sign-container">
          <p className="love">love</p>
          <p className="sign">Linn &amp; Herman</p>
        </div>
      </div>
    </TextSectionContainer>
  );
};

export const TextSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  width: 100%;
  height: auto;
  padding: 0.2rem;
  margin-bottom: 3rem;

  @media ${device.sm} {
    height: auto;
    margin-bottom: 5rem;
  }

  h2 {
    font-size: 2.5rem;
    margin: 0;
    padding: 0 0 1rem 0;
    text-align: end;
    font-weight: 600;
  }

  .border {
    padding: 2rem;

    @media ${device.md} {
      padding: 3rem;
      border-right: 2px solid black;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 4.5rem;

    @media ${device.md} {
      padding-top: 0;
    }
  }

  .inner-text-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  p {
    text-align: end;
  }

  .important-list-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    text-align: end;

    h4 {
      font-family: "Cormorant", serif;
      text-transform: uppercase;
      font-weight: 900;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      li {
        font-weight: 600;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }

        i {
          font-size: 0.8rem;
        }
      }
    }
  }

  .sign-container {
    padding-top: 1.5rem;
    p {
      font-family: "EyesomeScript", serif;
    }

    .love {
      font-size: 1.5rem;
    }

    .sign {
      font-size: 1.6rem;
    }
  }
`;
