import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ReactNode } from "react";

interface ScrollToTopProps {
  children: ReactNode;
}
export const ScrollToTop = (props: ScrollToTopProps) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Only re-run the effect if the pathname changes

  return <>{children}</>;
};
