import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Localized from "../../components/intl/Localized";
import { device } from "../../utils/constants/mediaQueries";

interface CountDownProps {}

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const CountDown = (props: CountDownProps) => {
  const calculateTimeLeft = (): TimeLeft => {
    // Target date
    const targetDate = new Date("2024-09-20");
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    let timeLeft: TimeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <StyledCountDown>
      <div className="count-down-group">
        <h4>
          <span>{timeLeft.days}</span>
          <Localized id="home.count-days" defaultMessage="Days" />
        </h4>
        <h4>
          <span>{timeLeft.hours}</span>
          <Localized id="home.count-hours" defaultMessage="Hours" />
        </h4>
      </div>
      <div className="count-down-group">
        <h4>
          <span>{timeLeft.minutes}</span>
          <Localized id="home.count-minutes" defaultMessage="Minutes" />
        </h4>
        <h4>
          <span className="seconds">{timeLeft.seconds}</span>
          <Localized id="home.count-seconds" defaultMessage="Seconds" />
        </h4>
      </div>
    </StyledCountDown>
  );
};

export const StyledCountDown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media ${device.sm} {
    flex-direction: row;
    gap: 1.5rem;
  }

  .count-down-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;

    @media ${device.sm} {
      gap: 1.5rem;
    }
  }

  h4 {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.2rem;
    font-size: 2rem;

    @media ${device.sm} {
      font-size: 2rem;
    }

    span {
      font-family: "EyesomeScript", serif;
    }

    .seconds {
      width: 2.5rem;
      text-align: center;
    }
  }
`;
