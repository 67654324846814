import { Button } from "primereact/button";
import styled from "styled-components";
import { ButtonProps } from "primereact/button";

interface MyButtonProps extends ButtonProps {
  label: any;
}

export default function MyButton(props: MyButtonProps) {
  const { label, ...rest } = props;
  return <StyledMyButton label={label} {...rest} />;
}

export const StyledMyButton = styled(Button)`
  letter-spacing: 1px;
  :hover {
    opacity: 0.7;
  }

  &:focus {
    box-shadow: 0 0 0.5px #fff, 0 0 0 2px gray, 0 0px 0px 0 #000;
  }

  &.p-button-text {
    padding: 0.5rem 0.2rem;
    font-size: 0.9rem;

    .pi {
      font-size: 0.85rem;
    }
  }

  &.p-button-text:focus {
    box-shadow: 0 0 0px #fff, 0 0 0 0px gray, 0 0px 0px 0 #000;
  }
`;
