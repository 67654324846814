import { drawLine } from "../../../styles/sharedStyles";
import useLangSearchParam from "../../../utils/hooks/useLangSearchParam";
import MyButton from "../../common/MyButton";
import styled from "styled-components";

export const LangButtons = () => {
  const { lang, changeLang } = useLangSearchParam("en");

  const engActive = lang === "en" ? "active" : "";

  const norActive = lang === "no" ? "active" : "";

  const itActive = lang === "it" ? "active" : "";

  console.log("lang:", lang);

  return (
    <StyledLanguageButtons>
      <MyButton
        text
        label="nor"
        onClick={() => changeLang("no")}
        className={norActive}
      />
      <MyButton
        text
        label="en"
        onClick={() => changeLang("en")}
        className={engActive}
      />
      <MyButton
        text
        label="it"
        onClick={() => changeLang("it")}
        className={itActive}
      />
    </StyledLanguageButtons>
  );
};

export const StyledLanguageButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  .p-button.p-button {
    position: relative; // Added to contain the ::after pseudo-element
    padding: 0.2rem 0.9rem 0.3rem 0.9rem;
    font-size: 1.1rem;
    /* text-transform: uppercase; */
  }

  .p-button.p-button:focus {
    box-shadow: none;
  }

  .active {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: black;
      animation: ${drawLine} 1s forwards;
    }
  }
`;
