import styled from "styled-components";
import { device } from "../../../utils/constants/mediaQueries";

export interface FooterProps {}

export const MyFooter = (props: FooterProps) => {
  return (
    <StyledFooter>
      <div className="links">
        <a
          href="https://www.instagram.com/casalmontani/?hl=nb"
          target="_blank"
          rel="noreferrer"
        >
          <i className="pi pi-instagram" style={{ fontSize: "1rem" }} />
          <p>Casal Montani Instagram</p>
        </a>
        <a
          href="https://maps.app.goo.gl/akQ3mvRAPfDDnxnW6"
          target="_blank"
          rel="noreferrer"
        >
          <i className="pi pi-map-marker" style={{ fontSize: "1rem" }} />
          <p>Casal Montani</p>
        </a>
      </div>

      <div className="c">
        <div>©</div>
        <div> 2023</div>
        <div>|</div>
        <h6>made by linn</h6>
      </div>
    </StyledFooter>
  );
};

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 13vh;
  width: 100%;
  max-width: 100%;
  padding: 1.3rem 1rem 0.5rem 1rem;
  background: black;
  color: white;

  @media ${device.sm} {
    height: 12vh;
    padding: 1.6rem 1rem 0.5rem 1rem;
  }

  .links {
    display: flex;
    justify-content: center;
    gap: 1rem;

    @media ${device.sm} {
      gap: 2rem;
    }
  }

  p {
    margin: 0;
    font-size: 0.9rem;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-decoration: none;
    color: white;
  }

  .c {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;

    gap: 0.5rem;
    width: 100%;

    @media ${device.sm} {
      justify-content: flex-end;
    }
  }
`;
