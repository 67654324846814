import { Field, FieldProps } from "formik";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { SelectItemOptionsType } from "primereact/selectitem";
import { concatClassnames } from "../../../utils/Helpers/concatClassNames";
import { StyledFormikField } from "./sharedStyles";

export interface FormikDropdownProps extends DropdownProps {
  label?: string;
  name: string;
  options: SelectItemOptionsType;
  required?: boolean;
}

export const FormikDropdown = (props: FormikDropdownProps) => {
  const { label, disabled, name, options, required, onChange, ...rest } = props;

  return (
    <StyledFormikField
      className={concatClassnames([
        "formik-dropdown-container",
        disabled && "field--disabled",
      ])}
    >
      {!!label && (
        <label
          htmlFor={name}
          onClick={() => {
            console.log(props);
          }}
        >
          {required ? label + "*" : label}
        </label>
      )}
      <Field name={name}>
        {(props: FieldProps<FormikDropdownProps["value"]>) => {
          const { field, meta } = props;
          // const error = getIn(form.errors, name);
          // const touched = getIn(form.touched, name);

          return (
            <div className="input-error-container">
              <Dropdown
                inputId={name}
                options={options}
                disabled={disabled}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onChange?.(e);
                }}
                {...rest}
                value={field.value ?? null}
              />
              {meta.touched && meta.error ? (
                <div className="input-error-container__error-msg">
                  {meta.error}
                </div>
              ) : null}
            </div>
          );
        }}
      </Field>
    </StyledFormikField>
  );
};
