import React, { ReactNode } from "react";
import styled from "styled-components";
import { TopBar } from "../Nav/TopBar";
import { MyFooter } from "./Footer";

// Define the styled components

// Define the prop types
interface AppLayoutProps {
  children: ReactNode;
  isLandingPage?: boolean;
}

// Define the layout component
const MyAppLayout: React.FC<AppLayoutProps> = ({ children, isLandingPage }) => {
  return (
    <LayoutContainer>
      <header>
        <StyledTopBar>
          <TopBar />
        </StyledTopBar>
      </header>
      <Content>{children}</Content>
      {!isLandingPage && <MyFooter />}
    </LayoutContainer>
  );
};

export default MyAppLayout;

export const StyledTopBar = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1100;
`;

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

export const Content = styled.main`
  flex: 1;
`;

export const Footer = styled.footer`
  background-color: black;
  color: white;
  padding: 1rem;
  text-align: center;
  height: 80px;
  width: 100%;
`;
