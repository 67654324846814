import { styled } from "styled-components";
import MyAppLayout from "../../../components/Layout/Layout/MyAppLayout";
import { TextPageLayout } from "../../../components/Layout/Layout/TextPageLayout";
import Localized from "../../../components/intl/Localized";
import casalMontaniSvartHvit from "../../../images/casalMontani/casal-montani-svarthvit.jpg";
import { device } from "../../../utils/constants/mediaQueries";
import cheers from "../../../images/svg/cheers.svg";
import { CasalMontaniGalleriaSmallerImages } from "../../../components/Galleria/CasalMontaniGalleriaSmallerImages";

interface CasalMontaniPageProps {}

const CasalMontaniPage = (props: CasalMontaniPageProps) => {
  return (
    <MyAppLayout>
      <TextPageLayout>
        <StyledContainer>
          <div className="header-text-section">
            <div className="background-img">
              <h1>Casal Montani</h1>
            </div>

            <p>
              <Localized
                id="casalMontani.p1"
                defaultMessage="Casal Montani was the last venue we visited on our wedding location hunt in Italy in the summer of 2023. 
                By that time, we had already viewed five different venues, all incredibly beautiful – as expected in Italy! 
                The morning we were to visit Casal Montani, we received a message to postpone the meeting due to an unforeseen event. 
                We almost considered canceling, as we had already seen several stunning wedding venues, and were tempted to spend the day 
                at the beach in Nettuno after some hectic days of driving in a small Fiat Panda on the narrow and bumpy Italian roads. 
                Despite this, we decided to go to Casal Montani that day – a decision we definitely do not regret! We both felt that this place was right, 
                and that we didn't need to look any further."
              />
            </p>
            <p>
              <Localized
                id="casalMontani.p2"
                defaultMessage="Casal Montani is a historic property located in the heart of Castelli Romani, near Rome. 
                This place is part of the Roman countryside near the town of Frascati. Casal Montani produces wine and is known for 
                its beautiful medieval building, its historical connection to the Roman Empire, and the stunning views over Rome, 
                Monti Tiburtini, and Castelli Romani."
              />
            </p>

            <p>
              <Localized
                id="casalMontani.p3"
                defaultMessage="The wines produced here rest and mature in their historic cellar with oak barrels and terracotta amphorae, 
                a tradition that dates back to Roman times. We are pleased to offer our guests a small tour of the wine cellar during the 
                wedding reception, so you can see where and how the wines are stored and have the opportunity to get a little taste!"
              />
            </p>
          </div>

          <div className="img-container">
            <img
              className="illustration-img"
              src={cheers}
              alt="An illustration of two glasses clinking together in a toast."
            />
          </div>

          <div className="galleria-wrapper">
            <div className="galleria-header-text">
              <h2>
                <Localized
                  id="casalMontani.h2"
                  defaultMessage="Casal Montani - Summer and Winter 2023"
                />
              </h2>
              <p>
                <Localized
                  id="casalMontani.p4"
                  defaultMessage="A small collection of photos taken during our visits to Casal Montani in July and December 2023."
                />
              </p>
            </div>

            <CasalMontaniGalleriaSmallerImages />
          </div>
        </StyledContainer>
      </TextPageLayout>
    </MyAppLayout>
  );
};

export default CasalMontaniPage;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .background-img {
    background-image: url(${casalMontaniSvartHvit});
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 100%;
    height: 200px;
    position: relative;

    @media ${device.sm} {
      height: 250px;
      margin-bottom: 2rem;
    }
  }

  .background-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* White with 0.5 opacity */
    z-index: 1; /* Ensure the overlay is above the background image */
  }

  h1 {
    z-index: 100;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    text-align: center;
    margin: 0;
    font-size: 2.7rem;
    font-weight: 500;

    @media ${device.xs} {
      font-size: 3.5rem;
    }

    @media ${device.sm} {
      top: 50%;
      font-size: 5rem;
    }
  }

  .heading {
    font-weight: 900;
    font-size: 1.2rem;
  }

  h2 {
    @media ${device.md} {
      font-size: 1.6rem;
    }
  }
  .img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 1rem;

    @media ${device.sm} {
      padding: 8rem 0;
    }

    .illustration-img {
      width: 100px;
    }
  }

  .galleria-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .galleria-header-text {
    display: flex;
    flex-direction: column;

    gap: 1rem;
  }
`;
