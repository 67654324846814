import styled from "styled-components";
import { concatClassnames } from "../../utils/Helpers/concatClassNames";
import { drawLine } from "../../styles/sharedStyles";

export interface _CommandBarLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  isActive?: boolean;
  noLink?: boolean;
  pageLink?: boolean;
}

function _CommandBarLink(props: _CommandBarLinkProps) {
  const { children, isActive, noLink, pageLink, ...rest } = props;

  // Default content if no children and no title is provided
  // This is to avoid any accessibility issues
  const defaultContent = "";

  return (
    <a
      aria-label={props.title || defaultContent}
      role="presentation"
      {...rest}
      className={concatClassnames([
        props.className,
        "p-component",
        "command-bar-link",
        !!isActive && "command-bar-link--active",
        !!noLink && "command-bar-link--no-link",
        !!pageLink && "command-bar-link--page-link",
      ])}
    >
      {children || defaultContent}
    </a>
  );
}

export const CommandBarLink = styled(_CommandBarLink)`
  text-decoration: none;
  padding: 0.35rem;
  width: fit-content;

  &:hover {
    opacity: 0.7;
  }
  &.command-bar-link--active {
    position: relative;
  }
  &.command-bar-link--active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: fit-content;
    padding-right: 10px;
    background-color: var(--primary-300);
    animation: ${drawLine} 1s forwards;
  }

  &.command-bar-link--no-link {
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }

  &.command-bar-link--page-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--primary-500);
    font-weight: 900;
    font-size: 1.1rem;
  }
`;
