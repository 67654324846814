import styled from "styled-components";
import { MyFooter } from "./Footer";

interface SaveTheDateLayoutProps {
  children: any;
}

export const SaveTheDateLayout = (props: SaveTheDateLayoutProps) => {
  const { children } = props;

  return (
    <StyledSaveTheDateLayout>
      <div className="container">{children}</div>
      <MyFooter />
    </StyledSaveTheDateLayout>
  );
};

export const StyledSaveTheDateLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 100%;

  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }
`;
